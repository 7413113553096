@import "../variables/breakpoints";
@import "./src/stylesheets/variables";

/*
  breakpoint mixin with optional fallback
*/

@mixin respond-to($medias...) {
  $breakpoints-length: length($respond-to-breakpoints);

  @each $media in $medias {
    $had-a-hit: false;

    @for $i from 1 through $breakpoints-length {
      $breakpoint: nth($respond-to-breakpoints, $i);

      @if $media == nth($breakpoint, 1) {
        $definition: nth($breakpoint, 2);
        $had-a-hit: true;

        @media #{$definition} {
          @content;
        }
      }
    }

    // if these variables are not defined, skip generating fallback and warn
    @if global-variable-exists(respond-to-no-mediaqueries-fallback)
      and global-variable-exists(respond-to-fallback-class) {
      @if $media == $respond-to-no-mediaqueries-fallback {
        .#{$respond-to-fallback-class} & {
          @content;
        }
      }
    }

    @if $had-a-hit == false {
      @warn "Media #{media} not found!";
    }
  }
}
