
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

.b-select-color {
  position: relative;

  .dropdown-button {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 100%;
    border: solid $border-width $color-light-grey;
    padding: $single-space;
    text-align: left;

    &:hover,
    &:focus {
      background-color: $color-lighter-grey;
    }

    .dropdown-name {
      display: inline-block;
      margin-bottom: 0;
      padding-right: $half-space;
      font-size: $base-font-size;
      line-height: $base-line-height;
      vertical-align: top;
      color: $color-black;
    }

    .color-indicator {
      overflow: hidden;
      box-sizing: border-box;
      position: relative;
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-right: $half-space;
      border: $border-width solid $color-light-grey;
      vertical-align: top;
      color: transparent;
      background-color: $color-white;
    }

    .ico {
      position: absolute;
      top: 50%;
      right: 15px;
      padding: 0;
      font-size: $content-font-size-xxsh;
      font-weight: $content-font-weight-bold;
      transform: translateY(-50%);
    }
  }
}
