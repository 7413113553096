
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

/*
 * DesignCrossSellListContainer
 */

.b-design-cross-sell-list,
.b-all-products-list {
  @include respond-to(desktop) {
    margin: 0 0 53px;
    padding-bottom: 13px;
  }

  @include respond-to(tablet-down) {
    margin: 0 0 $double-space;
    padding-bottom: $single-space;

    .h3 {
      text-align: center;
    }

    .h4 {
      display: block;
    }

    .b-swiper {
      height: auto;
    }
  }

  overflow: hidden;
  border-bottom: $medium-border-width solid $color-lighter-grey;
  text-align: center;

  &.x-no-border {
    margin-bottom: 33px;
    border-bottom: 0 none;
    padding-bottom: 0;
  }

  .b-design-grid {
    text-align: center;
  }

  .b-swiper {
    padding: 0;
  }

  .item-pic {
    max-width: none;
  }

  .swiper-slide {
    width: auto;

    &:first-child .b-design-item {
      padding-left: 0;
    }

    &:last-child .b-design-item {
      padding-right: 0;
    }
  }

  .item-in-collection-link {
    display: block;
    font-size: $content-font-size-xsh;
    line-height: $content-line-height-xxs;
    color: $color-dark-blue;

    &:visited {
      color: $color-gray-pressed;
    }

    .ico-collection-in {
      margin-right: $half-space;
    }
  }

  .message {
    margin-top: $single-space;
  }

  .unit-price {
    margin: -$half-space 0 $single-space;
    color: $color-dark-grey;
  }
}
