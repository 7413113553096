
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-user-submitted-photos {
  @include respond-to(desktop) {
    border-top: $medium-border-width solid $color-lighter-grey;
  }

  .user-submitted-photo-btn {
    display: inline-block;
    margin: 0 0 $single-space;
  }

  .b-swiper {
    height: auto;
    padding-bottom: $double-space;

    .swiper-wrapper {
      width: auto;
      max-height: 150px;
    }

    .swiper-slide {
      width: auto;
      height: 100px;
      margin-right: $single-space;
    }

    .button-prev,
    .button-next {
      top: 43%;
    }
  }
}
