// Button-Extends
@import "src/stylesheets/variables";

// Standard Button-Placeholder
%btn {
  appearance: none;
  position: relative;
  display: inline-block;
  margin: 0;
  border: 0 none;
  padding: 0;
  font-family: $font-primary;
  font-size: $base-font-size;
  line-height: $base-line-height;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

%btn:focus,
%btn:hover {
  outline: none; // don't forget to define an alternative styling!
}

%btn[disabled] {
  cursor: not-allowed;
}

%blue-button {
  border: $border-width solid $blue-button;
  background-color: $blue-button;
  color: $color-white;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: darken($blue-button, $hover);
  }

  &.x-dark {
    border: $border-width solid $color-dark-blue;
    background-color: $color-dark-blue;
  }

  &.x-disabled,
  &[disabled] {
    border: $border-width solid rgba($blue-button, $disabled);
    background-color: rgba($blue-button, $disabled);
  }

  &.x-hero-image {
    @include respond-to(desktop) {
      margin-top: 17px;
    }

    @include respond-to(tablet) {
      margin-top: $single-space;
    }
  }

  &.x-100-percent-width {
    width: 100%;
  }
}

%big-button {
  padding: $single-space $double-space;
  font-size: $content-font-size-s;
  line-height: $base-line-height;
}
