
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

/*
 * Quantity Changer
 */

.b-quantity-changer {
  position: relative;
  display: inline-block;
  margin: $double-space $single-space 0 0;

  &.x-fabric-page-quantity-changer {
    position: absolute;
    z-index: 2;
    top: 17px;
    left: 0;
    margin: 0;
  }

  .change-quantity-input[type="number"] {
    appearance: textfield;
  }

  .change-quantity-input::-webkit-outer-spin-button,
  .change-quantity-input::-webkit-inner-spin-button {
    appearance: none;
  }

  .change-quantity-label,
  .change-quantity-input {
    display: inline-block;
    line-height: $base-line-height;
    vertical-align: top;

    &:focus .ico {
      color: $color-black;
    }
  }

  .ico {
    padding: 0;
    font-size: $content-font-size-xxxs;
    font-weight: $content-font-weight-bold;
    line-height: $content-line-height-xxxs;
  }

  .change-quantity-input {
    width: 45px;
    height: 36px;
    border: $border-width solid $color-light-grey;
    border-radius: $border-radius-curved;
    padding: 0 $half-space 0 17px;
    font-family: $font-primary;
    font-size: $base-font-size;
    line-height: $base-line-height;
  }

  .quantity-button {
    position: absolute;
    right: 10px;
    line-height: $content-line-height-xxs;
    color: $color-black;

    &:hover,
    &:focus {
      color: $color-blue;
    }

    &[disabled] {
      color: $color-white;
    }

    &.up-button {
      top: 1px;
    }

    &.down-button {
      bottom: 1px;
    }
  }
}
