
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-design-info-box {
  &.x-products-overview {
    border-top: 0 none;
    padding: 0 0 $triple-space;
    text-align: center;

    .h3 {
      @include lato-uppercase;

      margin-bottom: $quadruple-space;
      font-size: $base-font-size;
      font-weight: $content-font-weight-bold;
      line-height: 1;
    }

    .info-avatar-wrapper {
      float: none;
      display: inline-block;
      margin: 0 0 $double-space;
      border-radius: $border-radius-none;
      vertical-align: top;
    }

    .info-text-wrapper {
      padding: 0 25%;
    }

    .b-tags-list {
      display: inline-block;
      margin-bottom: 14px;
      white-space: normal;
      vertical-align: top;
    }
  }

  .x-trigger-extra-small {
    font-weight: $content-font-weight-semibold;
  }

  .design-info-text {
    padding-right: 3px;
    white-space: pre-line;

    .design-description {
      display: inline-block;
    }
  }

  .continuation-indicator {
    padding-left: $half-space;
  }

  .trigger {
    margin-top: $single-space;

    .text,
    .ico {
      color: $color-blue;
    }
  }
}
