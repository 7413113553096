
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-rating-bar {
  top: 0;
  left: $pentuple-space;
  margin-bottom: $triple-space;

  .rating-bar {
    position: relative;
    display: inline-block;
    width: 70%;
    height: $single-space;
    margin: 6px $half-space 0 0;
    border: $border-width solid $color-dark-blue;
    border-left: 0 none;
    vertical-align: top;

    &::after {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-image:
        repeating-linear-gradient(
            90deg,
            $color-blue,
            $color-blue 1px,
            transparent 1px,
            transparent 19.95%
          );
      content: "";
    }
  }

  .rating-bar-content {
    display: block;
    height: 100%;
    background-color: $color-dark-blue;
  }
}
