
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

/*
 * Design Item
 */

.b-design-item {
  @include respond-to(large-desktop) {
    max-width: 290px;
  }

  @include respond-to(small-desktop) {
    max-width: 285px;
  }

  @include respond-to(desktop) {
    .b-modal .modal-content {
      width: $modal-extra-wide-width;
      padding-top: 0;
    }
  }

  @include respond-to(tablet-down) {
    .collection-btn {
      width: auto;
    }
  }

  @include respond-to(tablet) {
    max-width: 340px;
  }

  @include respond-to(mobile) {
    max-width: 214px;
  }

  overflow: hidden;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  margin-bottom: $hextuple-space;
  border-radius: $border-radius-curved;
  text-align: left;

  @supports (display: grid) {
    max-width: none;
    margin: 0;
  }

  &:hover,
  &:focus {
    &.x-shadowed {
      box-shadow: $item-box-shadow-highlight;
    }

    .item-pic {
      transform: $image-zoom-hover;
    }
  }

  &.x-shadowed {
    box-shadow: $item-box-shadow;
    border: $border-width solid $color-lighter-grey;
  }

  &.x-condensed {
    display: inline-block;
    width: 174px;
    margin-bottom: 0;
    padding: $double-space 25px $single-space;
    text-align: left;
    vertical-align: top;

    .design-link {
      border: $border-width solid transparent;

      &:focus {
        border-color: $color-black;

        .item-pic {
          transform: $image-zoom-hover;
        }
      }
    }

    .item-pic {
      @include img-broken(174px);

      width: 174px;
      height: 174px;
    }

    .design-item-text {
      box-sizing: border-box;
      height: 26px;
      padding: $single-space 0 0;

      .item-author-wrapper {
        font-weight: $content-font-weight-normal;
      }
    }
  }

  &.x-large-cross-sell {
    width: 235px;
    padding: $double-space 15px $single-space;

    .item-pic {
      @include img-broken(235px);

      width: 235px;
      height: 235px;
    }

    .design-item-text {
      height: 26px;
      padding: 15px 0 0;
    }
  }

  .design-link {
    overflow: hidden;
    display: block;

    &:focus .item-pic {
      transform: $image-zoom-hover;
    }
  }

  .item-pic {
    display: block;
    width: 100%;
    height: auto;
    transition: transform .2s ease 0s;
    backface-visibility: hidden;
  }

  .design-item-text {
    padding: $double-space;

    .design-item-product-name {
      @include lato-uppercase(.5px);

      display: block;
      padding-bottom: 5px;
      font-size: $content-font-size-xxsh;
      font-weight: $content-font-weight-light;
      font-style: normal;
      line-height: $content-line-height-xxxs;
    }

    .design-link {
      @include ellipsis;

      display: block;
      padding-bottom: 5px;
      line-height: $content-line-height-xxsh;
      text-align: left;
      color: $color-black;
    }

    .item-author-wrapper {
      @include ellipsis;

      font-size: $content-font-size-xsh;
      font-weight: $content-font-weight-light;
      line-height: $content-line-height-xxs;

      .item-author {
        padding-left: 3px;
      }
    }

    .quick-add {
      margin-top: 25px;
      display: flex;

      .b-favorite-design,
      .collection-btn {
        vertical-align: top;

        .ico {
          font-size: $content-font-size-s;
          line-height: 1;
        }
      }

      .collection-btn {
        font-size: $content-font-size-s;
        color: $color-mid-grey;

        &:hover .ico,
        &:focus .ico {
          color: $color-black;
        }

        &.collected .ico {
          color: $color-dark-blue;
        }
      }

      .b-favorite-design .favorite-btn {
        display: block;
        padding-right: $double-space;
      }
    }
  }

  .design-item-text .design-link,
  .design-item-text .item-author,
  .item-in-collection-link {
    &:hover,
    &:focus,
    &:hover span,
    &:focus span {
      text-decoration: underline;
      color: $color-blue;

      &.ico {
        text-decoration: none;
      }
    }
  }

  .b-modal .modal-with-button {
    margin-bottom: 0;
  }

  .all-products-link {
    overflow: hidden;
    box-sizing: border-box;
    display: block;
    margin-top: auto;
    border-top: $border-width solid $color-light-grey;
    padding: $single-space 0;
    font-size: $content-font-size-xsh;
    line-height: $content-line-height-xxs;
    text-align: center;
    white-space: nowrap;
    color: $color-platinum-gray;

    &:hover,
    &:focus {
      border-color: $color-platinum-gray;
      text-decoration: underline;
      color: $color-black;
    }

    .ico {
      position: relative;
      top: 1px;
      margin-right: 5px;
      line-height: $content-line-height-s;
      color: $color-platinum-gray;
    }
  }
}

.see-more-like-this-container {
  position: relative;
}

.see-more-like-this {
  background-color: $color-white;
  color: $color-gray-700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 6px 12px;
  text-align: center;
  border: $border-width solid $color-gray-200;
  border-radius: $border-radius-curved;
  min-width: 100px;
  font-weight: bold;
}
