
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-featured-in-collection {
  @include respond-to(large-desktop) {
    display: flex;
    flex: 1 auto;

    .featured-in-collection-image-wrapper,
    .featured-thumbnail {
      width: 80px;
      height: 80px;
    }

    .design-thumbnail {
      width: 64px;
      height: 64px;
    }
  }

  @include respond-to(desktop) {
    border-right: $border-width solid $color-light-grey;

    .featured-in-collection-image-wrapper {
      margin: $double-space;
    }
  }

  @include respond-to(small-desktop) {
    box-sizing: border-box;
    display: inline-flex;
    width: 50%;
  }

  @include respond-to(small-desktop-down) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .featured-in-collection-image-wrapper {
      width: 60px;
      height: 60px;
    }

    .design-thumbnail,
    .featured-in-collection-img {
      width: 50px;
      height: 50px;
    }

    .featured-in-collection-wrapper.b-text-box .wrapper {
      margin-bottom: $double-space;
    }
  }

  @include respond-to(tablet-down) {
    display: block;
    width: 100%;
    border-bottom: $border-width solid $color-light-grey;

    .featured-in-collection-image-wrapper {
      margin: $double-space auto $single-space;
    }

    .featured-in-collection-wrapper {
      text-align: center;
    }
  }

  &:visited {
    color: $color-teal-pressed;
  }

  .main-text {
    font-weight: $content-font-weight-semibold;
  }

  .featured-in-collection-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;

    &.b-text-box {
      border: 0 none;
      padding: 0;

      .h4 {
        margin-bottom: $half-space;
        font-size: $content-font-size-s;
        line-height: $content-line-height-s;
        color: $color-black;
      }
    }
  }

  .featured-in-collection-image-wrapper {
    position: relative;
  }

  .design-thumbnail {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    &.x-shadow {
      z-index: 1 !important;
      box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, .3);
    }
  }

  .featured-in-collection-img {
    @include img-broken(64);

    overflow: hidden;
  }
}
