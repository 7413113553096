
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-stacked-images {
  @include respond-to(large-desktop) {
    &.x-stacked-images-small {
      width: $small-stacked-image-size-large-desktop;
      height: $small-stacked-image-size-large-desktop;

      &.x-single {
        width: $small-thumbnail-size-large-desktop;
        height: $small-thumbnail-size-large-desktop;
      }
    }

    &.x-stacked-images-large {
      width: $large-stacked-image-size-large-desktop;
      height: $large-stacked-image-size-large-desktop;

      &.x-single {
        width: $small-thumbnail-size-large-desktop;
        height: $small-thumbnail-size-large-desktop;
      }
    }

    .x-thumbnail-small {
      width: $small-thumbnail-size-large-desktop;
      height: $small-thumbnail-size-large-desktop;
    }

    .x-thumbnail-large {
      width: $large-thumbnail-size;
      height: $large-thumbnail-size;
    }
  }

  @include respond-to(desktop) {
    margin: $double-space;
  }

  @include respond-to(small-desktop-down) {
    &.x-stacked-images-small {
      width: $small-stacked-image-size-small-desktop;
      height: $small-stacked-image-size-small-desktop;

      &.x-single {
        width: $small-thumbnail-size-small-desktop;
        height: $small-thumbnail-size-small-desktop;
      }
    }

    &.x-stacked-images-large {
      width: $large-stacked-image-size-large-desktop;
      height: $large-stacked-image-size-large-desktop;
    }

    .x-thumbnail-small {
      width: $small-thumbnail-size-small-desktop;
      height: $small-thumbnail-size-small-desktop;
    }

    .x-thumbnail-large {
      width: $large-thumbnail-size;
      height: $large-thumbnail-size;
    }
  }

  @include respond-to(tablet-down) {
    margin: $double-space auto $single-space;

    &.x-stacked-images-small.x-single {
      width: $small-thumbnail-size-small-desktop;
      height: $small-thumbnail-size-small-desktop;
    }
  }

  position: relative;

  &.x-stacked-images-large.x-single {
    width: $large-thumbnail-size;
    height: $large-thumbnail-size;
  }

  .design-thumbnail {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    &.x-shadow {
      z-index: $z-level-2;
      box-shadow: $item-box-shadow-highlight;
    }
  }

  .stacked-thumbnail {
    overflow: hidden;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .thumbnail-image {
    @include img-broken(64px);

    overflow: hidden;
  }
}
