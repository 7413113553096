
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-individual-review-list-item {
  @include respond-to(desktop) {
    .evaluate-review-answer {
      display: inline-block;
      margin-right: $single-space;
    }
  }

  @include respond-to(desktop, tablet) {
    padding: $double-space;
  }

  @include respond-to(tablet-down) {
    .review-section-left,
    .review-section-right {
      width: 100%;
    }

    .amount-of-answers {
      display: inline-block;
    }

    .evaluation-btn {
      width: auto;
    }
  }

  margin: $double-space 0 $single-space;
  border: $thick-border-width solid $color-lighter-grey;
  border-radius: $border-radius-curved;
  list-style: none;

  .individual-stars {
    margin-bottom: $triple-space;
  }

  .star-list-item {
    display: inline-block;
  }

  .user-name,
  .time-purchased {
    margin-bottom: $triple-space;
    font-weight: $content-font-weight-light;
    color: $color-mid-grey;
  }

  .time-created {
    font-weight: $content-font-weight-light;
    color: $color-mid-grey;
  }

  .review-text {
    margin: $half-space 0 $triple-space;
  }

  .evaluation-btn,
  .amount-of-answers {
    margin-right: 3px;
    font-weight: $content-font-weight-light;
    color: $color-blue;
  }

  .review-photos-section {
    overflow: hidden;
    list-style: none;

    .individual-review-photos {
      display: inline-block;
      margin: $single-space $single-space $single-space 0;
    }

    .review-photo-thumbnail {
      height: auto;
    }
  }
}
