
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/mixins";
@import "src/stylesheets/variables";

/*
 * Item Prices
 */
.b-item-price {
  overflow: hidden;
  position: relative;

  .item-price,
  .item-price-discounted {
    margin-right: $half-space;
    font-size: $content-font-size-s;
    line-height: $content-line-height-s;

    &.x-animate {
      animation: flashing 1s ease-out .2s 1;
    }
  }

  .item-price.x-discounted {
    position: relative;
    font-size: $content-font-size-s;
    line-height: $content-line-height-xxsh;
  }

  &.x-price-product {
    display: inline-block;

    .item-price {
      display: inline-block;
      font-size: $content-font-size-lh;
      line-height: $content-line-height-m;
    }
  }

  &.x-price-product,
  &.x-price-calculator-lp {
    .item-price-discounted {
      display: inline-block;
      font-weight: $content-font-weight-bold;
      color: $color-red;

      &.x-item-price-review {
        font-size: $content-font-size-lh;
        line-height: $content-line-height-m;
      }
    }

    .item-price.x-discounted {
      margin: 0 0 2px $single-space;
      font-size: $base-font-size;
      font-weight: $content-font-weight-normal;
      text-decoration: line-through;
      vertical-align: bottom;
      color: $color-dark-grey;
    }
  }

  &.x-price-calculator-lp {
    .item-price {
      font-size: $content-font-size-m;
      line-height: $content-line-height-m;
    }
  }

  &.x-price-cart {
    float: right;
    text-align: right;

    .item-price-per {
      margin: 0;
      padding-top: $half-space;
      font-size: $content-font-size-xxsh;
      line-height: $content-line-height-xxxs;
      letter-spacing: $letter-spacing-lato;
    }

    .item-price-discounted {
      display: block;
      margin: 0;
      padding: $half-space 0;
      font-size: $content-font-size-m;
      font-weight: $content-font-weight-bold;
      line-height: $content-line-height-s;
      color: $color-red;
    }

    .item-price.x-discounted {
      margin: 0;
      font-size: $base-font-size;
      font-weight: $content-font-weight-normal;
      line-height: $base-line-height;
      text-decoration: line-through;
      color: $color-mid-grey;
    }
  }

  &.x-price-wallpaper-calculator {
    overflow: visible;
    margin-left: $half-space;
  }
}
