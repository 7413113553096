// Color-Variables
// general colors
$color-gray-200: #b3b7ba;
$color-gray-500: #536063;
$color-gray-700: #303738;
$color-green-500: #1c8172;
$color-afterpay-green: #b2fce4; // afterpay only

// brand colors
$color-white: #fff; // backgrounds
$color-lightest-grey: #eee; // navigation
$color-lighter-grey: #efefef; // boxes, nav bg
$color-light-grey: #ddd; // steps, disabled
$color-mid-light-grey: #ccc; // borders
$color-mid-grey: #999; // borders, flash default
$color-dark-grey: #666; // borders, lighter text
$color-black: #333; // text
$color-deep-black: #000; // focus on black elements
$color-platinum-gray: #767676;
$color-robins-egg-blue: #e7f6f9; // navigational active background
$color-blue: #337179; // links
$color-dark-blue: #303738; // links hover
$color-teal-pressed: #0d5257; // teal links pressed
$color-gray-pressed: #0c0d0e; // gray links pressed
$color-green: $color-green-500; // confirmation, info - passes contrast test
$color-light-green: #e6f3e5; // cross sell
$color-yellow: #ecb201;  // cta
$color-light-yellow: #ffefb8; // background color for use with dark text
$color-red: #b10000; // warnings, errors
$color-light-red: #f8e1da; // background for notices, warnings

// Button backgrounds. Do not use for other purposes.
$blue-button: #2d6e76;
$green-button: #80a628;

// Hover grad, disabled half color for buttons
$hover: 6%;
$disabled: .5;

// box shadow for items like in design shop, fabric shop etc.
$item-box-shadow: 2px 2px 8px 0 rgba($color-black, .15);
$item-box-shadow-highlight: 2px 2px 8px 0 rgba($color-black, .25);

// box shadow for items like collection thumbnails
$item-box-deep-shadow: 2px 2px 2px 0 rgba($color-deep-black, .3);

// box shadow for color dots
$color-dots-text-shadow: 0 0 2px rgba($color-deep-black, .3);

// background color for the subscribers form, announcement/top/user bar
$chakra-gray-50: #f1f7f8;
$chakra-teal-500: #337179;
$chakra-gray-700: #303738;
$chakra-warm-white: #fefefe;

// States colors
$info-state-background-color: rgba(33, 150, 243, .08);
