/* splits flex-row into one growing component and one fixed-width */
%flex-split {
  position: relative;
  display: flex;
  flex-direction: row;
}

%flex-grow {
  overflow: auto;
  flex-grow: 1;
  word-wrap: break-word;
}

/* specify min-width or flex-basis for classes that use flex-fixed */
.flex-fixed {
  margin-left: $half-space;
  text-align: right;
}
