
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

/*
 * Avatar Image
 */

.b-avatar-image {
  @include respond-to(tablet-down) {
    display: block;
  }

  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 75px;

  .b-lazy-image {
    height: 100%;
  }

  .avatar-image {
    @include img-broken(75px);

    width: auto;
    height: 100%;
    max-width: none;
  }
}
