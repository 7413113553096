
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-product-info-fabric-details {
  @include respond-to(desktop, tablet) {
    .fabric-specs .spec-wrapper {
      display: inline-block;
      width: 50%;
      vertical-align: top;
    }

    .certificate-text {
      padding-left: 18px;
      line-height: 50px;
    }
  }

  @include respond-to(mobile) {
    .certificate {
      width: 34px;
      height: 34px;
    }

    .certificate-text {
      width: 91%;
      padding-left: $single-space;
      vertical-align: middle;
    }
  }

  .fabric-specs,
  .fabric-certificates,
  .fabric-cta {
    margin-top: $quadruple-space;
    border-top: $border-width solid $color-light-grey;
    padding-top: $quadruple-space;

    .spec-title {
      margin-bottom: $double-space;
    }

    .spec-list {
      .list-term {
        padding-bottom: 2px;
        font-weight: $content-font-weight-bold;
      }

      .list-description {
        margin-bottom: $double-space;

        .ico {
          padding-right: $single-space;
          font-size: $content-font-size-l;
        }
      }
    }

    + .fabric-certificates {
      margin-top: $double-space;
    }
  }

  .fabric-certificates {
    .certificates-title {
      margin-bottom: $double-space;
      font-weight: $content-font-weight-bold;
    }

    .certificate-box {
      overflow: hidden;
      box-sizing: border-box;
      width: 100%;
      min-height: 46px;
      max-width: 320px;
      border: $border-width solid $color-light-grey;
      border-radius: $border-radius-curved;
      padding: $single-space;
      text-align: center;
      white-space: nowrap;

      &,
      .certificate,
      .certificate-text {
        display: inline-block;
      }

      .certificate-text {
        box-sizing: border-box;
        font-style: normal;
        text-align: left;
        white-space: normal;
      }
    }
  }

  .fabric-cta {
    .fabric-price {
      margin-bottom: $quadruple-space;
    }

    .btn-shop-designs,
    .btn-select-different {
      margin: 0 15px $single-space 0;
      padding: 6px 22px;
      font-weight: $content-font-weight-normal;
    }

    // adding because this is getting overwritten by the %btn extend for unknown reasons.
    .white-button.x-dark {
      border: $border-width solid $color-light-grey;
    }
  }

  .btn-faq {
    font-weight: $content-font-weight-normal;
  }
}
