
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

/*
 * Field Inline Errors
 */

.b-inline-errors {
  opacity: 0;
  position: absolute;
  top: -9999px;
  left: -9999px;
  font-family: $font-secondary;
  font-style: italic;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  .x-error &,
  &.x-error {
    opacity: 1;
    position: static;
    top: auto;
    left: auto;
    color: $color-red;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    transition: opacity .3s ease-in .1s;
  }

  .x-error-black &,
  &.x-error-black {
    opacity: 1;
    position: static;
    top: auto;
    left: auto;
    color: $color-black;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    transition: opacity .3s ease-in .1s;
  }
}

[class^="type-"].x-error {
  label,
  .b-inline-errors {
    color: $color-red;
  }

  .text,
  .checkbox {
    border: 1px solid $color-red;
  }
}
