
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-promo-info {
  overflow: hidden;
  margin: $half-space 0;
  color: $color-red;

  .ico-tag {
    float: left;
    margin-right: $half-space;
    font-size: $content-font-size-s;
    line-height: $content-line-height-s;
  }

  .promo-name {
    font-size: $content-font-size-xsh;
    font-weight: $content-font-weight-bold;
    line-height: $content-line-height-s;
  }
}
