
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

/*
 * Radio Group
 */

.radio-group {
  @include respond-to(tablet-down) {
    &.x-search {
      width: 240px;
      margin-bottom: 10px;
    }
  }

  overflow: hidden;
  box-sizing: border-box;
  user-select: none;
  position: relative;
  width: 100%;
  border: 2px solid $color-lighter-grey;
  border-left: 0 none;
  border-radius: $border-radius-curved;
  white-space: nowrap;
  color: $color-black;
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
  transition-duration: .4s;
  -webkit-tap-highlight-color: transparent;

  &.x-vertical {
    display: flex;
    flex-direction: column;
  }
}

.radio-group > input {
  @include visuallyhidden;
}

.radio-group > input:disabled:nth-child(1) ~ label:nth-of-type(1) {
  opacity: .3;
  cursor: not-allowed;
}

.radio-group > input:nth-child(1):checked ~ label:nth-of-type(1)::after,
.radio-group > input:nth-child(1):checked ~ label:nth-of-type(1)::before {
  opacity: 1;
}

.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1)::after,
.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1)::before,
.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1) ~ label::after,
.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1) ~ label::before {
  left: 0;
}

.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2)::after,
.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2)::before,
.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2) ~ label::after,
.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2) ~ label::before {
  left: 0;
}

.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3)::after,
.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3)::before,
.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3) ~ label::after,
.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3) ~ label::before {
  left: 0;
}

.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4)::after,
.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4)::before,
.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4) ~ label::after,
.radio-group > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4) ~ label::before {
  left: 0;
}

.radio-group > input:disabled:nth-child(2) ~ label:nth-of-type(2) {
  opacity: .3;
  cursor: not-allowed;
}

.radio-group > input:nth-child(2):checked ~ label:nth-of-type(2)::after,
.radio-group > input:nth-child(2):checked ~ label:nth-of-type(2)::before {
  opacity: 1;
}

.radio-group > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2)::after,
.radio-group > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2)::before,
.radio-group > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2) ~ label::after,
.radio-group > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2) ~ label::before {
  left: 50%;
}

.radio-group > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3)::after,
.radio-group > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3)::before,
.radio-group > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3) ~ label::after,
.radio-group > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3) ~ label::before {
  left: 33.3333%;
}

.radio-group > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4)::after,
.radio-group > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4)::before,
.radio-group > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4) ~ label::after,
.radio-group > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4) ~ label::before {
  left: 25%;
}

.radio-group > input:disabled:nth-child(3) ~ label:nth-of-type(3) {
  opacity: .3;
  cursor: not-allowed;
}

.radio-group > input:nth-child(3):checked ~ label:nth-of-type(3)::after,
.radio-group > input:nth-child(3):checked ~ label:nth-of-type(3)::before {
  opacity: 1;
}

.radio-group > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3)::after,
.radio-group > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3)::before,
.radio-group > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3) ~ label::after,
.radio-group > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3) ~ label::before {
  left: 66.6666%;
}

.radio-group > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4)::after,
.radio-group > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4)::before,
.radio-group > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4) ~ label::after,
.radio-group > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4) ~ label::before {
  left: 50%;
}

.radio-group > input:disabled:nth-child(4) ~ label:nth-of-type(4) {
  opacity: .3;
  cursor: not-allowed;
}

.radio-group > input:nth-child(4):checked ~ label:nth-of-type(4)::after,
.radio-group > input:nth-child(4):checked ~ label:nth-of-type(4)::before {
  opacity: 1;
}

.radio-group > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4)::after,
.radio-group > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4)::before,
.radio-group > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4) ~ label::after,
.radio-group > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4) ~ label::before {
  left: 75%;
}

.radio-group > label {
  float: left;
  display: inline-block;
  border-left: 2px solid $color-light-grey;
  text-decoration: inherit;
  cursor: pointer;
  transition-timing-function: inherit;
  transition-duration: inherit;
  transition-property: none;
}

.radio-group > label::before,
.radio-group > label::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.radio-group > label::after {
  opacity: 0;
  z-index: $z-level-0;
  padding: inherit;
  text-align: center;
  text-decoration: inherit;
  color: $color-black;
  content: attr(data-value);
  transition-timing-function: inherit;
  transition-duration: inherit;
  transition-property: left;
}

.radio-group > label::after,
.radio-group > label {
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;
}

.radio-group > label::before {
  color: inherit;
  background-color: $color-light-grey;
  content: "";
  transition-timing-function: inherit;
  transition-duration: inherit;
  transition-property: left;
}

.radio-group > label:first-of-type:nth-last-of-type(1),
.radio-group > label:first-of-type:nth-last-of-type(1)::after,
.radio-group > label:first-of-type:nth-last-of-type(1)::before,
.radio-group > label:first-of-type:nth-last-of-type(1) ~ label,
.radio-group > label:first-of-type:nth-last-of-type(1) ~ label::after,
.radio-group > label:first-of-type:nth-last-of-type(1) ~ label::before {
  width: 100%;
}

.radio-group > label:first-of-type:nth-last-of-type(2),
.radio-group > label:first-of-type:nth-last-of-type(2)::after,
.radio-group > label:first-of-type:nth-last-of-type(2)::before,
.radio-group > label:first-of-type:nth-last-of-type(2) ~ label,
.radio-group > label:first-of-type:nth-last-of-type(2) ~ label::after,
.radio-group > label:first-of-type:nth-last-of-type(2) ~ label::before {
  width: 50%;
}

.radio-group > label:first-of-type:nth-last-of-type(3),
.radio-group > label:first-of-type:nth-last-of-type(3)::after,
.radio-group > label:first-of-type:nth-last-of-type(3)::before,
.radio-group > label:first-of-type:nth-last-of-type(3) ~ label,
.radio-group > label:first-of-type:nth-last-of-type(3) ~ label::after,
.radio-group > label:first-of-type:nth-last-of-type(3) ~ label::before {
  width: 33.3333%;
}

.radio-group > label:first-of-type:nth-last-of-type(4),
.radio-group > label:first-of-type:nth-last-of-type(4)::after,
.radio-group > label:first-of-type:nth-last-of-type(4)::before,
.radio-group > label:first-of-type:nth-last-of-type(4) ~ label,
.radio-group > label:first-of-type:nth-last-of-type(4) ~ label::after,
.radio-group > label:first-of-type:nth-last-of-type(4) ~ label::before {
  width: 25%;
}

.radio-group.x-vertical > label:first-of-type:nth-last-of-type(2),
.radio-group.x-vertical > label:first-of-type:nth-last-of-type(2)::after,
.radio-group.x-vertical > label:first-of-type:nth-last-of-type(2)::before,
.radio-group.x-vertical > label:first-of-type:nth-last-of-type(2) ~ label,
.radio-group.x-vertical > label:first-of-type:nth-last-of-type(2) ~ label::after,
.radio-group.x-vertical > label:first-of-type:nth-last-of-type(2) ~ label::before {
  width: 100%;
  height: 50%;
}

.radio-group.x-vertical > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2)::after,
.radio-group.x-vertical > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2)::before,
.radio-group.x-vertical > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2) ~ label::after,
.radio-group.x-vertical > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2) ~ label::before {
  top: 50%;
  left: 0;
}
