
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

/*
 * Search
 */
.b-search-box {
  @include respond-to(large-desktop) {
    display: flex;
    padding: $double-space 0;

    &.x-search-wrapper-full-width {
      width: 100%;
    }

    &.x-collection-search {
      .search-text-input-wrapper,
      .search-text-input-wrapper .search-input {
        width: 600px;
      }
    }

    .search-text-input-wrapper .search-input {
      padding: $single-space;
    }

    .x-auto-fill-list {
      top: 21px;
      left: -1px;
      width: 100.5%;
      max-width: $search-input-width + 2;
    }
  }

  @include respond-to(desktop) {
    &.x-substrate-shop.x-search-wrapper-full-width {
      margin: 0 $triple-space 0 $quadruple-space;
    }
  }

  @include respond-to(small-desktop) {
    &.x-substrate-shop {
      width: calc(70% - 10px); // radio-group 30% + margin
    }
  }

  @include respond-to(small-desktop-down) {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    padding: 0 $double-space;

    &.x-substrate-shop {
      width: 100%;
      margin-bottom: $single-space;

      .search-text-input-wrapper {
        .search-input {
          height: 38px;
        }

        .search-box-button {
          top: 3px;
        }
      }
    }

    .search-text-input-wrapper {
      width: 100%;
      max-width: none;

      .search-input {
        width: 100%;
        padding: 7px $single-space;
        font-size: $content-font-size-s;
      }

      .search-box-button {
        top: 0;
        right: 3px;
      }
    }

    .x-auto-fill-list {
      top: 19px;
      left: -1px;
      width: 100.2%;
    }
  }

  @include respond-to(tablet-down) {
    &.x-search-wrapper-full-width {
      display: block;
    }

    &.x-search-wrapper-full-width,
    .search-box-button,
    .clear-search-button {
      width: auto;
    }

    .x-auto-fill-list {
      border-top: 0 none;
      border-right: 0 none;
    }
  }

  @include respond-to(tablet) {
    &.x-search-wrapper-full-width {
      margin: 0 25px 0 $double-space;
    }

    .x-auto-fill-list {
      width: 100.3%;
    }
  }

  @include respond-to(mobile) {
    &.x-search-wrapper-full-width {
      margin: 0 $double-space;
    }

    .x-auto-fill-list {
      width: 100.5%;
    }
  }

  display: inline-block;
  text-align: left;
  white-space: nowrap;
  vertical-align: top;

  .search-text-input-wrapper {
    position: relative;
    display: inline-block;
    flex-grow: 1;
    width: 100%;
    height: 39px;
    border: $border-width solid $color-light-grey;
    border-radius: 30px / 30px;
    padding: 0;
    vertical-align: top;
    z-index: auto;

    .search-input {
      position: absolute;
      z-index: auto;
      top: 0;
      left: $single-space;
      box-sizing: border-box;
      appearance: none;
      width: 84%;
      height: 37px;
      outline: none;
      margin-bottom: 0;
      border: 0 none;
      font-size: $content-font-size-s;
      line-height: $content-line-height-xxsh;
      background-color: transparent;

      &:focus {
        border-color: $color-mid-grey;
      }

      ::placeholder {
        color: $color-light-grey;
      }
    }

    .search-box-button {
      position: absolute;
      z-index: auto;
      top: 3px;
      right: 3px;
      margin: 0;
      border: 0 none;
      padding: 8px 14px;
      background-color: transparent;

      &:hover .ico-search,
      &:focus .ico-search {
        color: $color-black;
      }

      .ico-search {
        margin: 0 0 2px 1px;
        font-size: $content-font-size-s;
        color: $color-mid-grey;
      }
    }

    .clear-search-button {
      position: absolute;
      top: 1px;
      right: 50px;
      width: 35px;
      border: 0 none;
      padding: 6px;
      line-height: $content-line-height-m;

      &:hover .ico-close,
      &:focus .ico-close {
        color: $color-black;
      }

      .ico-close {
        padding: 0;
        font-size: $content-font-size-xxs;
        line-height: $content-line-height-xxs;
        color: $color-mid-grey;
      }
    }
  }

  &.x-collection-search {
    margin-bottom: $septuple-space;
    padding: 0;
    font-size: $content-font-size-s;
    line-height: $content-line-height-s;
    text-align: center;

    .search-text-input-wrapper {
      max-width: 600px;
      margin: 0 auto;

      .search-box-button {
        top: $half-space;
      }
    }
  }

  &.x-search-wrapper-full-width {
    .search-text-input-wrapper,
    .search-input {
      width: 84%;
    }
  }

  .x-auto-fill-list {
    box-sizing: border-box;
    position: absolute;
    z-index: $z-level-5;
    margin-top: 0;
    border-right: $border-width solid $color-light-grey;
    border-bottom: $border-width solid $color-light-grey;
    border-left: $border-width solid $color-light-grey;
    border-radius: 0 0 30px 30px;
    padding: 20px 0 0;
    text-align: left;
    background-color: $color-white;

    .auto-fill-item {
      overflow: hidden;
      padding: $half-space 17px;
      background-color: $color-white;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $color-lightest-grey;
      }

      &:last-of-type {
        border-radius: 0 0 30px 30px;
        padding-bottom: 15px;
      }

      .search-query {
        padding-right: 3px;
        font-weight: $content-font-weight-bold;
      }
    }

    .x-option-active {
      background-color: $color-lightest-grey;
    }
  }

  .x-auto-fill-list::before {
    position: absolute;
    top: 19px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-light-grey;
    content: "";
  }
}
