
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-favorite-design {
  @include respond-to(tablet-down) {
    .favorite-btn {
      width: auto;
    }
  }

  display: inline-block;
  white-space: nowrap;

  &.x-fabric-page-favorite-design {
    margin-right: $double-space;
  }

  &.x-shop-page-favorite-design {
    padding-top: 15px;
  }

  .favorite-btn {
    padding: 0 $half-space 0 0;

    .ico-heart {
      font-size: $content-font-size-l;
      line-height: $content-line-height-l;
    }
  }

  .unfavorited {
    color: $color-gray-500;

    &:hover,
    &:focus-visible {
      color: $color-black;
    }
  }

  .favorited {
    color: $color-red;

    &:hover,
    &:focus-visible {
      color: darken($color-red, 20%);
    }
  }

  .favorite-amount {
    display: inline-block;
    margin-right: $half-space;
    font-size: $content-font-size-xxs;
    line-height: $content-line-height-xxxxs;
    vertical-align: text-top;
    color: $color-gray-500;
  }
}
