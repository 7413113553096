
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-available-substrates-links {
  @include respond-to(small-desktop-down) {
    .other-substrate-link-wrapper {
      margin-bottom: $double-space;
    }
  }

  @include respond-to(tablet-down) {
    text-align: center;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;

  .other-substrate-link-wrapper {
    display: inline-block;
    width: auto;
    font-weight: $content-font-weight-semibold;

    &:visited {
      color: $color-teal-pressed;
    }
  }
}
