
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/blocks/buttons";
@import "src/stylesheets/extends/buttons";
@import "src/stylesheets/mixins";

.b-add-to-cart {
  @extend %btn;
  @extend %big-button;
  @extend %blue-button;

  // adding because this is getting overwritten by the %btn extend for unknown reasons.
  border: $border-width solid $color-blue;
  border-radius: $border-radius-curved;
}

.b-button-group .b-add-to-cart[disabled] {
  border: 0 none;
}
