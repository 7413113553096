
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

/*
 * Substrate Shop
 */

.b-substrate-shop {
  @include respond-to(large-desktop) {
    .radio-group {
      max-width: 100px;
    }

    .top-filters .b-search-box.x-substrate-shop {
      width: 280px;
      height: 39px;
      padding: 0;

      .search-text-input-wrapper {
        height: 35px;
        margin-top: 1px;
        border: $border-width solid $color-light-grey;
        border-radius: 0;

        .search-input {
          left: 0;
          padding: $single-space;
        }
      }

      .search-text-input-wrapper .clear-search-button {
        z-index: $z-level-7;
        top: 1px;
        right: 49px;
        padding: 4px;
      }
    }
  }

  @include respond-to(small-desktop) {
    .radio-group {
      width: 30%;
    }
  }

  @include respond-to(desktop) {
    .top-filters {
      display: flex;
      justify-content: flex-end;
    }

    .substrate-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .filter-heading {
      text-align: right;
    }

    .heading-container {
      padding-top: $triple-space;
      display: flex;
      align-items: center;
      gap: $double-space;
    }

    .radio-group {
      margin-left: $single-space;
    }

    .no-results {
      max-width: 60%;
      margin: 15px 0 $double-space $single-space;
    }
  }

  @include respond-to(small-desktop-down) {
    .button-wrapper-fixed {
      position: fixed;
      z-index: $z-level-2;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      width: auto;
      margin: 0;
      border-top: $border-width solid $color-light-grey;
      padding: $double-space;
      background-color: $color-white;
    }
  }

  @include respond-to(tablet-down) {
    .substrate-heading {
      display: block;
    }

    .top-filters {
      padding-top: $double-space;
      display: block;
    }

    .filter-heading {
      text-align: left;
      min-width: 175px;
    }

    .heading-container {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @include respond-to(tablet-down) {
    .shop-content {
      width: 100%;
    }

    .radio-group {
      max-width: 100%;
    }

    .order-item-list {
      text-align: center;

      li:last-child {
        border: none;
      }
    }

    .no-results {
      max-width: 90%;
    }
  }

  @include respond-to(mobile) {
    .order-item-list {
      margin-bottom: 42px;
    }
  }

  padding-bottom: $double-space;

  .selected-category {
    border-radius: $double-space;
    background-color: $color-lighter-grey;
    text-transform: capitalize;
    padding: 7px 12px;
    text-align: center;
  }

  .order-item-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: $quadruple-space 0 $half-space;

    @supports (display: grid) {
      @include respond-to(large-desktop) {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: $grid-gap-l;
      }

      @include respond-to(small-desktop, tablet) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: $grid-gap-m;
      }

      @include respond-to(mobile) {
        grid-template-columns: repeat(1, 1fr);
      }

      display: grid;
      grid-row-gap: $hextuple-space;
      justify-content: normal;
    }

    .b-modal & {
      display: flex;
      grid-row-gap: 0;
      flex-direction: column;
    }
  }

  .radio-group {
    position: relative;
    height: 38px;

    .type-label {
      font-size: $content-font-size-xs;
      line-height: 33px;

      &:last-child {
        border-left: 0 none;
      }
    }
  }

  .b-search-filters {
    float: left;
    width: 214px;
  }

  .category-information {
    .b-subheader {
      display: inline-block;
      padding-bottom: 0;
    }

    .sub-title {
      position: relative;
      margin-bottom: 0;
      text-transform: capitalize;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .category-filter {
    padding: 0 0 $single-space $single-space;
  }

  .no-results {
    position: relative;
    margin: $hextuple-space auto 0;
    font-size: $content-font-size-l;
    line-height: $content-line-height-l;
    text-align: center;
  }

  .b-substrate-search + .shop-content .order-item-list {
    justify-content: left;
  }

  .clear-filters-no-results {
    padding: 0 $half-space;
    font-size: $content-font-size-l;
    line-height: $content-line-height-l;
    color: $color-blue;
  }

  .fabric-shop-modal-title {
    margin: $double-space 0;
    font-size: $content-font-size-xl;
    line-height: $content-line-height-xl;
    text-align: center;
  }
}
