
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-user-submitted-photos-modal {
  @include respond-to(desktop) {
    padding: $double-space $quadruple-space $quadruple-space;
  }

  @include respond-to(desktop, tablet) {
    .review-section-left {
      box-sizing: border-box;
      float: left;
      width: 50%;
      padding-right: $single-space;
    }

    .review-section-right {
      float: right;
      width: 50%;
    }

    .review-photos-section {
      float: right;
      width: 50%;
    }

    .replies-wrapper {
      margin: $triple-space 0 0 $hextuple-space;
    }
  }

  @include respond-to(tablet-down) {
    padding: $quadruple-space;
  }

  @include respond-to(mobile) {
    .b-individual-review-list-item {
      padding: $double-space;
    }
  }

  .photo-modal-heading {
    margin-bottom: $triple-space;
    text-align: center;
  }

  .comment-reply-btn {
    padding: 8px $double-space;
  }

  .b-swiper {
    .swiper-wrapper {
      align-items: center;
    }

    .user-submitted-photo {
      width: auto;
      height: auto;
      max-height: 380px;
      margin-bottom: $triple-space;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    .button-prev,
    .button-next {
      top: 35%;
    }
  }

  .b-form {
    padding: 0 0 $double-space;

    .reply-fieldset,
    .comment-fieldset {
      margin-bottom: 0;
    }
  }

  .fieldset-wrapper {
    display: flex;
    justify-content: space-between;

    .field-wrapper {
      flex: 0 1 auto;
      width: 100%;
    }

    .type-textarea {
      .textarea {
        box-sizing: border-box;
        width: 100%;
        height: 100px;
        border: $border-width solid $color-light-grey;
        padding: $single-space;
        font-size: $content-font-size-xs;
        line-height: $content-line-height-xs;
        resize: vertical;
      }

      .x-error {
        border-color: $color-red;
      }
    }

    .btn {
      margin-top: 15px;
    }
  }

  .x-reply-form {
    width: auto;
    margin: $double-space 0 0 $triple-space;
  }

  .ico-arrow-return {
    flex: 0 1 auto;
    margin-right: $single-space;
    font-size: $content-font-size-xl;
    line-height: $content-line-height-xl;
    color: $color-mid-grey;
  }

  .replies-wrapper {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin-top: $triple-space;

    .replies-list {
      flex: 0 1 auto;
      width: 100%;
    }
  }

  .b-individual-review-list-item {
    overflow: hidden;
  }

  .individual-stars {
    display: inline-block;
  }

  .sub-heading {
    font-weight: $content-font-weight-bold;
  }

  .list-of-answers {
    margin-bottom: $triple-space;
  }

  .answer {
    display: inline;
    margin-right: $half-space;
  }

  .b-rating-bar {
    top: 0;
    left: $pentuple-space;
    margin-bottom: $triple-space;

    .rating-bar {
      position: relative;
      display: inline-block;
      width: 70%;
      height: $single-space;
      margin: 6px $half-space 0 0;
      border: $border-width solid $color-dark-blue;
      border-left: 0 none;
      vertical-align: top;

      &::after {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-image:
          repeating-linear-gradient(
              90deg,
              $color-blue,
              $color-blue 1px,
              transparent 1px,
              transparent 19.95%
            );
        content: "";
      }
    }

    .rating-bar-content {
      display: block;
      height: 100%;
      background-color: $color-dark-blue;
    }
  }

  .individual-review-photos {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    .review-photo-thumbnail {
      margin: 0 $single-space $single-space 0;
    }
  }
}
