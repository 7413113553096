
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

/*
 * Design Detail Image
 */

.b-design-detail-image {
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: top;

  &.x-fabric-ripple {
    max-height: 525px;

    .image,
    .image .fallback-image {
      width: auto;
      height: auto;
    }
  }

  .wrapper-positioning {
    overflow: hidden;
    position: relative;
    width: auto;
    height: 100%;
    margin: 0 auto;
  }

  .image {
    overflow: hidden;
  }

  .image,
  .image .fallback-image {
    display: inline-block;
    height: 100%;
  }
}
