@import "src/stylesheets/mixins";
@import "src/stylesheets/variables";
@import "src/stylesheets/extends/buttons";

/**
 * Buttons
 */

button,
.btn {
  @include respond-to(tablet-down) {
    width: 100%;
  }

  @extend %btn;

  line-height: $content-line-height-s;
  background-color: transparent;
}

.blue-button,
.green-button,
.white-button,
.grey-button,
.black-button,
.image-overlay-button {
  @extend %btn;

  box-sizing: border-box;
  border-radius: $border-radius-curved;
  padding: 7px $double-space;
  font-weight: 700;
  color: $color-white;
  cursor: pointer;

  &:focus {
    text-decoration: underline;
  }

  &.x-disabled,
  &[disabled] {
    color: rgba($color-white, .5);
    cursor: not-allowed;
    pointer-events: none;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";

    // ie8 fallback

    &::before {
      @include respond-to(desktop) {
        top: 0;
        left: -40px;
      }

      @include respond-to(tablet-down) {
        top: 6px;
        left: 6px;
      }
    }

    img {
      opacity: .5;
    }
  }

  &.x-100-percent-width {
    width: 100%;
  }
}

.blue-button {
  border: $border-width solid $blue-button;
  background-color: $blue-button;
  color: $color-white;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: darken($blue-button, $hover);
  }

  &.x-dark {
    border: $border-width solid $color-dark-blue;
    background-color: $color-dark-blue;
  }

  &.x-disabled,
  &[disabled] {
    border: $border-width solid rgba($blue-button, $disabled);
    background-color: rgba($blue-button, $disabled);
  }

  &.x-hero-image {
    @include respond-to(desktop) {
      margin-top: 17px;
    }

    @include respond-to(tablet) {
      margin-top: $single-space;
    }
  }

  &.x-100-percent-width {
    width: 100%;
  }
}

.green-button {
  border: $border-width solid $green-button;
  background-color: $green-button;

  &:hover,
  &:focus {
    background-color: darken($green-button, $hover);
  }

  &.x-disabled,
  &[disabled] {
    background-color: rgba($green-button, $disabled);
  }

  &.x-emphasis {
    display: inline-block;
    margin: 15px $half-space;
    border-radius: $border-radius-curved-large;
    padding: $single-space $double-space;
    font-weight: $content-font-weight-normal;
    line-height: $content-line-height-xxsh;
    text-decoration: none;
  }
}

.robins-egg-blue-button {
  border: $border-width solid $color-light-grey;
  border-radius: $border-radius-curved;
  background-color: $color-robins-egg-blue;

  &:hover,
  &:focus {
    background-color: darken($color-robins-egg-blue, $hover);
  }

  &.x-disabled,
  &[disabled] {
    background-color: rgba($color-robins-egg-blue, $disabled);
  }
}

.white-button {
  border: $border-width solid $color-blue;
  color: $color-blue;
  background-color: $color-white;

  &:hover,
  &:focus {
    color: $color-dark-blue;
    background-color: darken($color-white, $hover);
  }

  &.x-disabled,
  &[disabled] {
    border: $border-width solid rgba($color-blue, $disabled);
    color: rgba($color-blue, $disabled);
    background-color: rgba($color-white, $disabled);
  }

  &.x-dark {
    border-color: $color-light-grey;
    color: $color-black;
  }

  &.x-square {
    padding: 8px;
  }

  &.x-hero-image {
    @include respond-to(desktop, tablet) {
      border: none;
    }
  }

  &.x-subheader {
    @include lato-uppercase(1.5px);

    border-color: $color-light-grey;
    padding: 3px $single-space;
    font-weight: $content-font-weight-normal;
    line-height: 1;
    color: $color-mid-grey;
  }

  &.x-dark-text {
    padding: 6px 12px;
    font-weight: $content-font-weight-bold;
    color: $color-mid-grey;

    &:hover,
    &:focus {
      border-color: $color-black;
      color: $color-black;
      background-color: darken($color-white, $hover);
    }
  }

  // AfterpayInfoBox button styles
  &.x-info-box {
    border-color: $color-mid-grey;
    padding: $single-space;
    font-size: $content-font-size-xs;
    font-weight: $content-font-weight-normal;
    color: $color-mid-grey;

    &:hover,
    &:focus {
      border-color: $color-black;
      color: $color-mid-grey;
      background-color: darken($color-white, $hover);
    }
  }

  &.x-airy {
    border-color: $color-light-grey;
    padding: $single-space $double-space;
    font-weight: $content-font-weight-normal;
    color: $color-black;
  }
}

.grey-button {
  border: $border-width solid $color-lighter-grey;
  color: $color-black;
  background-color: $color-lighter-grey;

  &:hover,
  &:focus {
    color: $color-black;
    background-color: darken($color-lighter-grey, $hover);
  }

  &.x-disabled,
  &[disabled] {
    color: rgba($color-black, $disabled);
    background-color: rgba($color-lighter-grey, $disabled);
  }
}

.black-button {
  border: $border-width solid $color-black;
  background-color: $color-black;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: darken($color-black, $hover);
  }

  &.x-disabled,
  &[disabled] {
    border: $border-width solid transparent;
    background-color: rgba($color-black, $disabled);
  }

  &.x-dark-cart-btn {
    padding: $half-space 15px;
    font-weight: $content-font-weight-normal;
    line-height: $single-space;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-lato;
  }

  &.x-100-percent-width {
    width: 100%;
  }
}

.filter-button {
  padding: 9px 14px 9px 9px;
  font-size: $content-font-size-xsh;
  font-weight: $content-font-weight-normal;
  line-height: $content-line-height-xxs;

  .ico {
    padding-right: 7px;
  }
}

.small-button {
  padding: 3px 4px;
  font-size: $content-font-size-xxs;
  line-height: $content-line-height-xxs;

  &.border-dark {
    border: $border-width solid $color-dark-blue;
    border-radius: $border-radius-curved;
    color: $color-dark-blue;
  }
}

.shop-btn {
  width: 80px;
  font-size: $content-font-size-xs;
  font-weight: normal;
  line-height: $content-line-height-xs;
  text-transform: uppercase;
}

.big-button {
  padding: $single-space $double-space;
  font-size: $content-font-size-s;
  line-height: $base-line-height;
}

.individual-filter-btn {
  width: 100%;
  margin-top: $half-space;
  border-top: $border-width solid $color-light-grey;
  padding: 12px 17px;
  font-size: $content-font-size-xxsh;
  line-height: $content-line-height-xxsh;
  text-align: left;
  text-transform: uppercase;
  color: $color-mid-grey;
  background-color: transparent;

  .individual-filter-text {
    padding-left: 2px;
  }
}

.link-button {
  text-decoration: none;
  color: $color-blue;

  &:hover,
  &:focus {
    outline: 0 none;
    color: $color-black;
  }

  &.x-disabled,
  &[disabled] {
    color: $color-light-grey;
    pointer-events: none;
  }

  &.x-small {
    @include respond-to(desktop) {
      padding: $single-space $single-space 8px;
    }

    @include respond-to(tablet-down) {
      padding: 14px 7px;
      font-size: $content-font-size-xs;
    }
  }

  &.x-dark {
    color: $color-dark-blue;
  }
}

.next-to-input {
  @include respond-to(tablet-down) {
    margin-left: 0;
  }

  position: relative;
  margin-left: $half-space;
  line-height: 1;
}

.image-overlay-button {
  opacity: .8;
  padding-right: $single-space;
  padding-left: $single-space;
  font-weight: normal;
  text-transform: none;
  color: $color-black;
  background-color: $color-white;

  &:hover {
    opacity: 1;
  }

  &.x-image-overlay-button-centered {
    @include center;
  }

  &.x-80-percent-width {
    width: 80%;
  }

  &.x-pattern-lib {
    position: relative;
  }
}

.x-non-full-width-mobile-button {
  @include respond-to(tablet-down) {
    width: initial;
  }
}

.x-text-button {
  // AfterpayInfoBox text button styles
  margin-bottom: $double-space;

  .link-button {
    font-size: $content-font-size-xsh;
    text-align: left;
    color: $color-dark-grey;
  }
}
