
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-available-on-products {
  @include respond-to(large-desktop) {
    white-space: nowrap;

    .product-wrapper {
      width: 50%;
      padding-top: $pentuple-space;
      white-space: normal;
    }
  }

  @include respond-to(desktop, tablet) {
    width: 50%;
    border-left: $border-width solid $color-light-grey;

    &,
    .product-image,
    .product-wrapper {
      display: inline-block;
      vertical-align: top;
    }

    .product-image {
      max-width: 220px;
    }
  }

  @include respond-to(small-desktop, tablet) {
    .product-wrapper {
      padding-top: $double-space;
    }
  }

  @include respond-to(mobile) {
    border-top: $border-width solid $color-light-grey;

    .link-button {
      padding-bottom: $double-space;
    }
  }

  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  padding: $double-space;

  &:first-child {
    border: 0 none;
  }

  .link-button {
    margin-right: $double-space;

    .product-image {
      width: 100%;
      height: auto;
    }
  }

  .product-wrapper .fabric-title-btn {
    text-align: left;

    &:hover .link,
    &:focus .link {
      text-decoration: underline;
    }

    .fabric-title {
      display: block;
      margin-bottom: 6px;
      font-size: $content-font-size-s;
      font-style: normal;
      line-height: $content-line-height-s;
    }

    .link {
      color: $color-blue;
    }
  }
}
