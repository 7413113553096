
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-product-info-grid {
  @include respond-to(large-desktop) {
    .grid .grid-item {
      width: 63%;
      max-width: 780px;
      margin-right: 5%;
    }
  }

  @include respond-to(small-desktop) {
    .grid .grid-item {
      width: 57%;
      margin-right: 6.2%;
    }
  }

  @include respond-to(desktop) {
    .b-collapsible .trigger .ico-chevron-down,
    .b-collapsible .trigger .ico-chevron-up {
      // to avoid rendering overlap when react is still removing it
      display: none;
    }

    .call-to-action-buttons {
      margin-top: 6px;
    }
  }

  @include respond-to(tablet-down) {
    ~ .b-modal .modal-content {
      width: 100%;
      padding: 0;
    }

    .call-to-action-buttons {
      margin-top: $quadruple-space;
    }
  }

  overflow: hidden;
  width: 100%;
  margin: 0 0 $quadruple-space;

  .h3 {
    @include lato-uppercase;

    display: inline-block;
    font-size: $base-font-size;
    font-weight: $content-font-weight-bold;
    line-height: $base-line-height;
  }

  .grid .grid-item {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;

    + .grid-item {
      float: none;
      width: auto;
    }
  }

  .b-collapsible {
    margin-bottom: 0;
  }

  .grid-item-center {
    overflow: hidden;
    box-sizing: border-box;
    padding: $half-space 0 2px 0;
  }

  .grid-item-right {
    margin-right: 0;
  }

  .call-to-action-buttons {
    text-align: center;

    .btn {
      height: 48px;
      margin-left: 15px;
      padding: 0 25px;
      font-weight: $content-font-weight-normal;
      line-height: 48px;

      &:first-child {
        margin: 0 0 $single-space 0;
      }
    }

    // adding because this is getting overwritten by the %btn extend for unknown reasons.
    .white-button.x-dark {
      border: $border-width solid $color-light-grey;
    }
  }

  .btn-explore-fabric {
    font-weight: $content-font-weight-normal;
  }
}
