
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-select-wallpaper {
  margin-top: $triple-space;

  .out-of-stock-dropdown {
    border: solid $border-width $color-red;
  }

  .substrate-name {
    @include hyphens;

    display: inline-block;
    margin-bottom: 0;
    padding-right: $half-space;
    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $color-black;
  }

  .substrate-change {
    display: block;
  }

  .out-of-stock-message {
    font-size: $content-font-size-xs;
    color: $color-red;
    line-height: $content-line-height-xs;
  }

  .h2 {
    .label-subtitle {
      color: $color-dark-grey;
      font-weight: $content-font-weight-normal;
      text-transform: none;
    }
  }

  .option-btn {
    margin: $half-space $half-space 0 0;

    &.x-fabric-size {
      display: block;
      width: 100%;
    }

    .radio {
      @include visuallyhidden;
    }

    .label-text {
      display: block;
      border: $border-width solid $color-light-grey;
      border-radius: $border-radius-curved;
      padding: 7px 11px;
      text-align: left;
      cursor: pointer;
    }
  }

  .option-btn-active .label-text {
    border: 2px solid $color-dark-grey;
    padding: 6px $single-space;
  }

  .preference-modal-link {
    font-size: $content-font-size-xshh;
    color: $color-dark-grey;
    line-height: $content-line-height-xxs;
    margin: $single-space 0;

    .button-text {
      text-decoration: underline;
    }
  }
}
