// Typography-Variables

// fonts
$font-primary: "Lato", sans-serif;

// is used in combination with font-style: italic, to notify, explain or warn/error
$font-secondary: "ParryPro", serif;

// Letter spacing for uppercase lato
$letter-spacing-lato: .15em;

// default
$base-font-size: 14px;
$base-line-height: 22px;

// font sizes
$content-font-size-xxxs: 8px;
$content-font-size-xxs: 10px;
$content-font-size-xxsh: 11px;
$content-font-size-xs: 12px;
$content-font-size-xsh: 13px;
$content-font-size-xshh: 14px;
$content-font-size-s: 16px;
$content-font-size-m: 18px;
$content-font-size-l: 20px;
$content-font-size-lh: 21px;
$content-font-size-xl: 24px;
$content-font-size-xlh: 28px;
$content-font-size-xxl: 30px;
$content-font-size-xxxl: 36px;

// line heights
$content-line-height-xxxxxs: 10px;
$content-line-height-xxxxs: 12px;
$content-line-height-xxxs: 14px;
$content-line-height-xxs: 16px;
$content-line-height-xxsh: 18px;
$content-line-height-xs: 20px;
$content-line-height-s: 22px;
$content-line-height-m: 24px;
$content-line-height-l: 26px;
$content-line-height-xlh: 28px;
$content-line-height-xl: 30px;
$content-line-height-xlh: 32px;
$content-line-height-xxl: 36px;
$content-line-height-xxhl: 38px;
$content-line-height-xxxl: 42px;
$content-line-height-xxxxl: 44px;

/*
  font weights
  NOTE: these are derived from the following line in our layout file:
  <script>WebFont.load({google: {families: ['Lato:300,400,400italic,700']}});</script>
*/
$content-font-weight-light: 300;
$content-font-weight-normal: 400;
$content-font-weight-normal-500: 500;
$content-font-weight-semibold: 600;
$content-font-weight-bold: 700;
