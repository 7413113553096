
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-shipping-info {
  @include respond-to(desktop) {
    margin-bottom: 54px;
  }

  .h3 {
    margin-bottom: $double-space;
  }

  .x-fat-border {
    padding: $quadruple-space;
  }

  .happiness-guarantee-link {
    &:visited {
      color: $color-teal-pressed;
    }
  }

  .help-page-link {
    &:visited {
      color: $color-teal-pressed;
    }
  }
}
