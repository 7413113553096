
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

.b-designer-info-box {
  .designer-info-text {
    padding-right: 3px;
  }

  .designer-description,
  .more-details,
  .subtitle {
    display: inline-block;
  }

  .designer-avatar {
    border-radius: 80px;
  }
}
