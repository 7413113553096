
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

/*
 * Collapsible
 */

.b-collapsible {
  position: relative;
  margin-bottom: $single-space;

  .subtitle {
    display: block;

    .text {
      padding-left: 25px;

      &.x-flush-left {
        padding-left: 0;
      }
    }
  }

  .subtitle-with-space {
    margin: $double-space 0 7px;

    &.x-extra-space {
      margin: $single-space 0 $quadruple-space;
    }
  }

  .trigger {
    display: inline-block;
    text-align: left;

    &:focus .text {
      text-decoration: underline;
    }
  }

  .x-trigger-extra-extra-small {
    font-size: $base-font-size;
    line-height: $base-font-size;
  }

  .x-trigger-extra-small {
    display: block;

    .text {
      padding-left: 15px;
    }

    .ico-plus,
    .ico-minus {
      font-size: $content-font-size-xxs;
    }
  }

  .x-trigger-small {
    font-size: $content-font-size-s;
    line-height: $content-line-height-s;
  }

  .x-trigger-large {
    font-size: $content-font-size-m;
    line-height: $content-line-height-m;

    &.x-full-width {
      width: 100%;
      text-align: left;
    }
  }

  .ico-plus,
  .ico-minus {
    left: 0;
  }

  .ico-chevron-down,
  .ico-chevron-up {
    right: 0;
  }

  .ico-plus,
  .ico-minus,
  .ico-chevron-up,
  .ico-chevron-down,
  .ico-add-circle,
  .ico-remove-circle {
    position: absolute;
    top: 6px;
    font-size: $base-font-size;
    transition: transform .5s ease-out 0s, opacity .25s ease-out 0s;
  }

  .ico-plus,
  .ico-chevron-down,
  .ico-add-circle {
    opacity: 1;
    transform: rotate(0deg);
  }

  .ico-minus,
  .ico-chevron-up,
  .ico-remove-circle {
    opacity: 0;
    transform: rotate(0deg);
  }

  .content,
  .content-above-trigger {
    display: none;
    white-space: normal;
  }

  // content can't be removed from DOM when swiper is inside
  &.x-user-photos {
    .content {
      overflow: hidden;
      position: absolute;
      display: block;
      height: 0;
    }

    &.x-active .content {
      position: relative;
      height: auto;
    }
  }

  .info-text {
    position: absolute;
    top: 1px;
    left: 140px;
    white-space: nowrap;
    color: $color-black;

    &.x-animate {
      animation: flashing 1s ease-out .2s 1;
    }
  }

  &.x-active,
  &.x-active-designer-info {
    .ico-plus,
    .ico-minus,
    .ico-chevron-down,
    .ico-add-circle,
    .ico-remove-circle {
      transform: rotate(180deg);
    }

    .ico-plus,
    .ico-minus,
    .ico-chevron-down,
    .ico-chevron-up,
    .ico-add-circle,
    .ico-remove-circle {
      transition-delay: 0s;
    }

    .ico-plus,
    .ico-chevron-down,
    .ico-add-circle {
      opacity: 0;
    }

    .ico-minus,
    .ico-chevron-up,
    .ico-remove-circle {
      opacity: 1;
    }
  }

  &.x-active .content {
    display: block;
    margin-left: 15px;
  }

  &.x-active .text {
    &.x-toggle-color {
      color: $color-black;
    }
  }

  &.x-active-designer-info {
    .content-above-trigger {
      display: inline;
    }
  }

  &.x-bottom-border {
    border-bottom: $medium-border-width solid $color-lighter-grey;
    padding-bottom: 15px;
  }
}
