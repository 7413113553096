
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-select-fabric {
  margin-top: $triple-space;

  .substrate-link {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 100%;
    border: solid $border-width $color-light-grey;
    padding: 8px $single-space;
    text-align: left;
    border-radius: 4px;

    &:hover,
    &:focus {
      background-color: $color-lighter-grey;
    }
  }

  .out-of-stock-dropdown {
    border: solid $border-width $color-red;
  }

  .substrate-image {
    @include img-broken(45px);

    overflow: hidden;
    display: inline-block;
    width: 45px;
    height: 45px;
    margin-right: $single-space;
    border: $border-width solid $color-light-grey;
    vertical-align: top;
  }

  .substrate-details-wrapper {
    overflow: hidden;
    display: inline-block;
    width: calc(100% - 57px);
  }

  .substrate-name {
    @include hyphens;

    display: inline-block;
    margin-bottom: 0;
    padding-right: $half-space;
    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $color-black;
  }

  .substrate-change {
    display: block;
  }

  .substrate-change-fabric {
    display: block;
    color: $color-blue;
  }

  .ico-drawer-arrow-left {
    position: absolute;
    top: 24%;
    right: 14px;
    font-size: $content-font-size-l;
    color: $color-black;
  }

  .link-wrapper {
    display: inline-block;

    .fabric-detail-link {
      text-decoration: underline;
      color: $color-dark-grey;

      .ico {
        padding-left: 3px;
        font-size: $content-font-size-xxsh;
      }
    }
  }

  .out-of-stock-message {
    font-size: $content-font-size-xs;
    color: $color-red;
    line-height: $content-line-height-xs;
  }

  .h2 {
    .label-subtitle {
      color: $color-dark-grey;
    }
  }
}
