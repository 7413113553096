
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-available-substrates {
  @include respond-to(large-desktop) {
    display: flex;
    flex: 1 auto;
  }

  @include respond-to(desktop) {
    .available-substrates-img {
      padding: $double-space;
    }
  }

  @include respond-to(small-desktop) {
    box-sizing: border-box;
    position: relative;
    display: inline-flex;
    width: 50%;
  }

  @include respond-to(small-desktop-down) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    vertical-align: top;

    .available-substrates-img {
      width: 60px;
      height: 60px;
    }
  }

  @include respond-to(tablet-down) {
    width: 100%;
    padding-top: $double-space;

    .available-substrates-header {
      margin-top: 12px;
      font-size: $content-font-size-s;
      line-height: $content-line-height-s;
    }
  }

  .available-products {
    position: relative;
  }

  .available-substrates-header {
    margin-bottom: $half-space;
    font-size: $content-font-size-s;
    line-height: $content-line-height-s;
    color: $color-black;
  }

  .ampersand {
    display: inline-block;
    padding: 0 $half-space;
  }
}
