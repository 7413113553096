
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-afterpay-teaser-box {
  @include respond-to(desktop, tablet) {
    .afterpay-modal-logo {
      align-self: center;
      width: 300px;

      // ie specific
      max-height: 60px;
    }

    .afterpay-motto {
      padding: $triple-space 0;
      font-size: $content-font-size-xxl;
      line-height: $content-line-height-s;
    }

    .afterpay-process-container {
      display: flex;
      flex-flow: row wrap;
      align-content: center;
      justify-content: space-around;

      .afterpay-process {
        width: 30%;
        padding: 0 15px;

        .afterpay-process-img {
          max-width: 100px;
        }
      }
    }

    .afterpay-legal {
      align-self: center;
      max-width: 70%;
    }
  }

  @include respond-to(desktop) {
    .afterpay-process-container {
      display: flex;
      flex-flow: row wrap;
      align-content: center;
      justify-content: space-around;

      .afterpay-process {
        width: 20%;
        padding: 0 15px;

        .afterpay-process-img {
          max-width: 100px;
        }
      }
    }
  }

  @include respond-to(tablet) {
    overflow-y: auto;
    height: 100%;

    .afterpay-modal-logo {
      align-self: center;
      width: 200px;
    }

    .afterpay-motto {
      padding: $triple-space 0 $double-space;
      font-size: $content-font-size-xl;
      line-height: $content-line-height-m;
    }

    .afterpay-process-container {
      -ms-flex-pack: center;

      .afterpay-process {
        padding: 15px;

        .afterpay-process-img {
          width: 60px;
        }
      }
    }
  }

  @include respond-to(mobile) {
    overflow-y: auto;
    height: 100%;

    .afterpay-modal-logo {
      width: 200px;
      max-width: 90%;

      // ie specific
      max-height: 60px;
    }

    .afterpay-motto {
      padding: $single-space 0;
      font-size: $content-font-size-l;
      line-height: $content-line-height-m;
      text-align: left;
    }

    .afterpay-process-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .afterpay-process {
        display: flex;
        flex-direction: row;
        padding: 15px 0 0;

        .afterpay-process-img {
          width: $pentuple-space;
          height: 100%;
          padding-right: $single-space;
        }

        .afterpay-process-caption {
          align-self: center;
          text-align: left;
        }
      }
    }

    .afterpay-legal {
      text-align: left;
    }
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 832px;
  max-height: 720px;
  padding: $triple-space;
  background-color: $color-afterpay-green;

  .afterpay-modal-close-button {
    align-self: flex-end;
    max-width: 41px;
    max-height: 41px;
    border: 1px solid transparent;
    color: $color-black;

    &:focus,
    &:hover {
      border-color: $color-black;
      border-radius: 20px;
    }

    .ico {
      padding: $single-space;
      font-size: $content-font-size-l;
    }
  }

  .afterpay-modal-logo {
    height: auto;
  }

  .afterpay-motto {
    max-width: 460px;
    margin: 0 auto;
    font-size: $content-font-size-xxxl;
    font-weight: $content-font-weight-bold;
    line-height: $content-line-height-xxxxl;
    letter-spacing: .7px;
    color: $color-deep-black;
    text-align: center;
  }

  .notice {
    margin-top: $quadruple-space;
    padding: $triple-space 13% $pentuple-space;
    text-align: center;
    color: $color-white;
    background-color: $color-black;

    .ico {
      margin-bottom: $double-space;
      font-size: 36px;
    }

    .notice-text {
      margin-bottom: $triple-space;
    }

    .white-button {
      margin-bottom: $double-space;
      padding: 14px 5.5%;
      font-weight: $content-font-weight-normal;
    }

    .link-text {
      display: block;
      color: $color-white;
    }
  }

  .afterpay-legal {
    padding-top: $triple-space;
    font-size: $content-font-size-xxs;
    line-height: $content-line-height-xxxs;
    letter-spacing: .3px;
    color: $color-deep-black;

    .link-text {
      color: $color-deep-black;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .afterpay-legal-link-text {
    padding-top: $single-space;
    font-size: $content-font-size-xxs;
    line-height: $content-line-height-xxxs;
    text-align: center;
    letter-spacing: .3px;
    color: $color-deep-black;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
