
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-product-form-fabric-details {
  // adding because this is getting overwritten by the %btn extend for unknown reasons.
  @include respond-to(desktop) {
    .big-button {
      padding: $single-space $double-space;
    }
  }

  @include respond-to(tablet-down) {
    margin-top: $double-space;

    // adding because this is getting overwritten by the %btn extend for unknown reasons.
    .big-button {
      padding: 7px $double-space;
    }
  }

  .fabric-detail-headline {
    margin-bottom: $half-space;
    font-weight: $content-font-weight-bold;
    line-height: 1;
    text-transform: uppercase;
  }

  .btn-more-info {
    display: inline-block;
    margin-bottom: $triple-space;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .btn-more-info,
  .btn-shop-designs,
  .btn-select-different,
  .btn-swatch-price {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .btn-shop-designs,
  .btn-select-different,
  .btn-swatch-price {
    display: block;
    width: 100%;
  }

  // adding because this is getting overwritten by the %btn extend for unknown reasons.
  .btn {
    border-radius: $border-radius-curved;
  }

  .black-button {
    border: $border-width solid $color-black;
  }

  .grey-button {
    border: $border-width solid $color-lighter-grey;
  }

  .btn-select-different {
    @include lato-uppercase(.91px);

    min-height: 28px;
    margin: $single-space 0;
    font-size: $content-font-size-xxs;
    line-height: $content-line-height-xxxxs;
  }
}
