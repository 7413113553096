
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-designer-navbar {
  @include respond-to(large-desktop) {
    display: flex;
  }

  @include respond-to(small-desktop) {
    @include clearfix;
  }

  @include respond-to(desktop) {
    margin-bottom: 53px;
  }

  @include respond-to(tablet-down) {
    margin-bottom: $hextuple-space;
  }

  @include respond-to(mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  max-width: 100%;
  border-radius: $border-radius-curved;
  background-color: $color-lighter-grey;

  &.x-fabric-available {
    margin: $pentuple-space 0 57px;
  }
}
