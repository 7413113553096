
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/mixins";
@import "src/stylesheets/variables";

/*
 * General text box
 */
.b-text-box {
  margin: $double-space 0;
  border: 1px dashed $color-green;
  padding: 15px $double-space;
  text-align: left;

  &.x-explanatory-text {
    clear: both;
    max-width: 430px;
    margin: 0 0 $single-space;
    border: 0 none;
    padding: 0;
    white-space: normal;

    .main-text {
      font-family: $font-secondary;
      font-style: italic;
    }
  }

  &.x-notice-text {
    clear: both;
    margin: $double-space 0 $triple-space;
    border: 0 none;
    padding: $double-space $triple-space;
    text-align: center;
    white-space: normal;
    color: $color-black;

    .notice-url {
      text-decoration: underline;
      color: $color-black;
    }

    &.x-info {
      background-color: $color-robins-egg-blue;
    }

    &.x-alert {
      background-color: $color-light-red;
    }
  }

  &.x-empty {
    padding-top: $single-space;

    .main-text {
      margin: $single-space $single-space $double-space 0;
      font-size: $content-font-size-s;
      line-height: $content-line-height-s;
    }
  }

  &.x-description {
    max-width: 420px;
    margin: 0 0 $single-space;
    border: 0 none;
    padding: 0;

    &.x-fine-text {
      font-size: $content-font-size-xsh;
      font-weight: $content-font-weight-light;
      line-height: $content-line-height-xxs;
    }
  }

  &.x-framed {
    margin: 0;
    border: 2px solid $color-mid-grey;
    padding: $double-space;
    font-size: $content-font-size-m;
    line-height: $content-line-height-m;
  }

  &.x-full-description {
    margin: $triple-space 0 0 0;
    border: $border-width solid $color-light-grey;
    border-radius: 4px;
    padding: $double-space;
  }

  &.x-highlighted {
    border: 0 none;
    padding: $single-space $double-space;
    background-color: $color-yellow;
  }

  &.x-blurb {
    border: 1px solid $color-mid-light-grey;
    border-right: none;
    border-left: none;
    padding: $triple-space $double-space;

    .h4 {
      font-weight: $content-font-weight-bold;
      text-transform: capitalize;
    }

    .main-text {
      margin-bottom: $single-space;
    }
  }

  .main-text {
    display: inline-block;
  }

  .check-list {
    margin-top: $half-space;

    li {
      position: relative;
      padding-left: 23px;

      @for $i from 1 through 3 {
        &:nth-child(#{$i}) .ico {
          animation: zoom-in .4s ease-in 0s + ($i * .4s) 1 forwards;
        }
      }
    }

    .ico {
      position: absolute;
      top: $half-space;
      left: 0;
      font-size: $content-font-size-s;
      color: $color-green;
      transform: scale(0, 0);

      .#{$fallback-ie9-class} & {
        transform: scale(1, 1);
      }
    }
  }
}
