
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

.b-product-form-price {
  position: relative;

  .b-item-price {
    .item-price,
    .item-price-discounted {
      margin: 0;
      font-size: $content-font-size-lh;
      line-height: 1;

      ~ .item-price-discounted {
        margin-right: $half-space;
      }
    }
  }

  .secondary-information {
    display: block;
    font-size: $content-font-size-xs;
    font-style: normal;
    line-height: $content-line-height-xs;

    .unit-price,
    .addition,
    .vat-info {
      display: inline-block;
      margin-right: $single-space;
    }

    .unit-price {
      color: $color-gray-500;
    }
  }

  .ico-tag {
    float: left;
    margin-right: $half-space;
    font-size: $content-font-size-s;
    line-height: $content-line-height-s;
  }

  .promo-info {
    margin-left: $double-space;
    font-size: $content-font-size-xsh;
    font-weight: $content-font-weight-bold;
    line-height: $content-line-height-s;

    &-wrapper {
      overflow: hidden;
      margin: $half-space 0;
      color: $color-red;
    }
  }
}
