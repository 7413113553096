// Animations keyframe

// flash prices
@keyframes flashing {
  0% {
    background-color: $color-yellow;
  }

  100% {
    background-color: transparent;
  }
}

// fade in flash messages
@keyframes flash {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// zoom checkmarks
@keyframes zoom-in {
  0% {
    transform: scale(0, 0);
  }

  50% {
    transform: scale(1.3, 1.3);
  }

  100% {
    transform: scale(1, 1);
  }
}

// Progress Animation
@keyframes progress-5steps-scale {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX($progress-5steps-scale);
  }
}

@keyframes progress-4steps-scale {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX($progress-4steps-scale);
  }
}

@keyframes progress-3steps-scale {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX($progress-3steps-scale);
  }
}

// Progress Step Fade In Animation
@keyframes step-activation {
  0% {
    background-color: $color-light-grey;
  }

  100% {
    background-color: $color-yellow;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
