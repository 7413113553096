// Spacings
$half-space: 5px;
$single-space: 10px;
$single-half-space: 15px;
$double-space: 20px;
$double-half-space: 25px;
$triple-space: 30px;
$triple-half-space: 35px;
$quadruple-space: 40px;
$quadruple-half-space: 45px;
$pentuple-space: 50px;
$pentuple-half-space: 55px;
$hextuple-space: 60px;
$hextuple-half-space: 65px;
$septuple-space: 70px;
$septuple-half-space: 75px;
$octuple-space: 80px;
$octuple-half-space: 85px;
$nonuple-space: 90px;
$dectuple-space: 100px;

// main chrome padding (header, main, footer) -- do not re-use
$app-horizontal-space-desktop: $quadruple-space; // 40px == 2.5rem
$app-horizontal-space-tablet: $double-space;
$app-horizontal-space-mobile: $single-space;

// HeaderFooter Space Only -- do not re-use
$header-space: $quadruple-space;
$mobile-header-space: 52px;
