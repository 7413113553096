/*
  Layout Helpers
*/

@mixin clearfix {
  display: block;

  &::after {
    visibility: hidden;
    display: block;
    clear: both;
    height: 0;
    font-size: 0;
    content: "";
  }
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin hyphens {
  hyphens: auto;
}

@mixin show {
  display: block !important;
}

@mixin hide {
  display: none !important;
}

/* print query mixin */

@mixin print {
  @media print {
    @content;
  }
}

@mixin visuallyhidden {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0 none;
  padding: 0;
  clip: rect(0 0 0 0);
}

@mixin loading {
  position: relative;

  &::before {
    @include cdn-background-url("loading.gif");

    opacity: 1;
    position: absolute;
    z-index: $z-level-1;
    width: 34px;
    height: 34px;
    content: "";
    transition: opacity .5s ease 0s;
  }
}

@mixin img-broken($img-width: 90px) {
  position: relative;

  &::after {
    @include cdn-background-url("fabrics/NO_IMAGE_PLACEHOLDER_LARGE.png");

    position: absolute;
    top: 0;
    left: 0;
    width: $img-width;
    height: $img-width;
    background-position: center 0;
    background-size: cover;
    content: "";
  }

  &:-moz-broken {
    @include cdn-background-url("fabrics/NO_IMAGE_PLACEHOLDER_LARGE.png");

    display: inline-block;
    width: $img-width;
    height: $img-width;
    background-position: center 0;
    background-size: cover;
  }
}

@mixin center($width: null, $height: null) {
  // source: https://www.sitepoint.com/centering-with-sass/
  position: absolute;
  top: 50%;
  left: 50%;

  @if not $width and not $height {
    transform: translate(-50%, -50%);
  } @else if $width and $height {
    width: $width;
    height: $height;
    margin: calc(-#{$width} / 2) 0 calc(-#{$height} / 2);
  } @else if not $height {
    width: $width;
    margin-left: -($width / 2);
    transform: translateY(-50%);
  } @else {
    height: $height;
    margin-top: -($height / 2);
    transform: translateX(-50%);
  }
}

@mixin lato-uppercase($letter-spacing: 2.1px) {
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
}

@mixin cdn-background-url($image) {
  background-image: url(#{$cdn-url}/production/images/#{$image});

  .x-development & {
    background-image: url(#{$cdn-url}/development/images/#{$image});
  }
}
