
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-info-box {
  @include respond-to(large-desktop) {
    .info-avatar-wrapper {
      width: 75px;
      height: 75px;
      border-radius: 75px;

      .avatar-image {
        width: 75px;
      }
    }
  }

  @include respond-to(desktop) {
    border-top: $medium-border-width solid $color-lighter-grey;
    padding: 54px 0;

    &:first-child {
      border-top: 0 none;
      padding-top: 0;
    }
  }

  @include respond-to(small-desktop-down) {
    .info-avatar-wrapper {
      width: 60px;
      height: 60px;
      border-radius: 60px;

      .avatar-image {
        width: 60px;
      }
    }
  }

  @include respond-to(tablet-down) {
    padding: $double-space 0;
  }

  overflow: hidden;

  &.x-icon-box .info-avatar-wrapper {
    border-radius: 50%;
    background-color: $color-lightest-grey;

    .avatar-image {
      width: 50px;
      height: auto;
      margin-top: 15px;
    }
  }

  .h3 {
    display: block;
    margin-bottom: $double-space;
  }

  .subtitle {
    margin-bottom: 0;
  }

  .info-avatar-wrapper {
    overflow: hidden;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px $double-space 0 0;

    &.x-no-radius {
      border-radius: $border-radius-none;
    }
  }

  .info-text-wrapper {
    overflow: hidden;

    .heading {
      padding-right: 3px;
      font-weight: $content-font-weight-bold;
    }
  }

  .info-heading {
    display: inline-block;
    padding-right: $half-space;
    font-weight: $content-font-weight-bold;
  }

  .learn-more-link {
    display: block;
    margin-top: $single-space;
    font-weight: $content-font-weight-semibold;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &:visited {
      color: $color-teal-pressed;
    }
  }
}
