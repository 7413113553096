
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

/*
 * Fabric Substrate
 */

.b-substrate-item {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  outline: 0 none;
  margin-bottom: $hextuple-space;
  box-shadow: $item-box-shadow;
  border: $border-width solid $color-lighter-grey;
  border-radius: $border-radius-curved;
  padding-bottom: $double-space;
  text-align: left;
  vertical-align: top;

  &:hover,
  &:focus {
    box-shadow: $item-box-shadow-highlight;

    &.x-selected {
      border-color: $color-black;
    }

    .b-lazy-image {
      transform: $image-zoom-hover;
    }
  }

  &.x-out-of-stock-substrate-item {
    &:hover,
    &:focus {
      .b-lazy-image {
        transform: none;
      }
    }
  }

  &[role="button"] {
    cursor: pointer;

    &:focus {
      outline: 0 none;
      border-color: $color-black;
    }
  }

  &.x-fabrics-modal.x-selected,
  &.x-selected {
    border: $medium-border-width solid $color-black;
  }

  &.x-fabrics-modal {
    width: auto;
    box-shadow: none;
    border: 0 none;
    border-radius: 0;
    padding-bottom: 0;

    .substrate-image-wrapper {
      margin-bottom: 0;
    }

    .substrate-description {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 0 $double-space $double-space 15px;
    }
  }

  .b-swiper,
  .substrate-image-wrapper {
    overflow: hidden;
    position: relative;
    margin: 0 auto $double-space;
    padding-bottom: 0;
    cursor: pointer;

    .button-prev,
    .button-next {
      top: 50%;
    }

    .b-lazy-image,
    .substrate-image {
      display: block !important; // needs to overwrite inline style
      width: 100%;
    }

    .substrate-image {
      height: auto;
    }

    .b-lazy-image {
      transition: transform .2s ease 0s;
      backface-visibility: hidden;
    }
  }

  .out-of-stock-image-wrapper {
    cursor: auto;
  }

  .out-of-stock-image {
    filter: brightness($out-of-stock-brightness);
  }

  .out-of-stock-message-wrapper {
    position: absolute;
    z-index: $z-level-1;
    inset: 0;
    margin: auto;
    max-width: $out-of-stock-message-width-curb;
    max-height: $pentuple-space;
  }

  .out-of-stock-message-text {
    text-align: center;
    font-size: $content-font-size-s;
    background-color: $color-lighter-grey;
    color: $color-black;
    padding: $single-space $single-space;
    border-radius: $border-radius-curved;
  }

  .substrate-description {
    padding: 0 $double-space;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    .substrate-subtitle {
      font-style: italic;
    }

    .item-name {
      margin: 0 0 $half-space;
      font-weight: $content-font-weight-bold;
    }

    .fabric-size {
      color: $color-gray-500;
    }

    .circle-list {
      padding-bottom: $half-space;
      list-style: disc;

      li {
        padding-left: $single-space;
      }
    }

    .list-term {
      padding-top: $single-space;
      list-style: disc;
      font-weight: $content-font-weight-bold;
      line-height: $content-line-height-xxsh;

      .list-term-item {
        margin-left: $double-space;
        padding-top: $single-space;
        font-weight: $content-font-weight-normal;
      }
    }

    .list-link {
      text-decoration: underline;
      color: $color-black;
      cursor: pointer;
    }

    .more-details {
      display: inline-block;
      margin: $single-space 0;

      .text {
        padding-left: 15px;
        font-size: $base-font-size;
        line-height: $base-line-height;
        color: $color-gray-500;
      }

      .ico {
        top: 6px;
        font-size: $content-font-size-xxxs;
        line-height: $content-line-height-xxxs;
        color: $color-gray-500;
      }
    }

    .price-select-wrapper {
      margin-top: auto;

      .price-per-unit {
        padding-bottom: $half-space;
        font-weight: $content-font-weight-bold;
      }

      .btn {
        display: inline-block;
        margin-right: $single-space;
        margin-bottom: $single-space;
      }

      // adding because this is getting overwritten by the %btn extend for unknown reasons.
      .black-button {
        border: $border-width solid $color-black;
        border-radius: $border-radius-curved;
        padding: 7px $double-space;

        &[disabled] {
          border: $border-width solid $color-black;
          color: $color-white;
          background-color: $color-black;
        }
      }

      // adding because this is getting overwritten by the %btn extend for unknown reasons.
      .white-button {
        border: $border-width solid $color-blue;
        border-radius: $border-radius-curved;
        padding: 7px $double-space;

        &:hover {
          color: $blue-button;
        }
      }

      .out-of-stock-button {
        border: $border-width solid $color-black;
        border-radius: $border-radius-curved;
        color: $color-black;
        padding: 7px $double-space;
      }

      .learn-more:hover {
        text-decoration: underline;
      }
    }
  }

  .substrate-label {
    font-size: $content-font-size-s;
    line-height: $content-line-height-m;
    padding: 4px 12px;
    background-color: $info-state-background-color;
    text-transform: uppercase;
    color: $color-black;
    border-radius: $border-radius-curved;
    margin-bottom: $single-space;
  }

  @include respond-to(large-desktop) {
    max-width: 325px;
  }

  @include respond-to(small-desktop) {
    max-width: 285px;
  }

  @include respond-to(desktop, tablet) {
    &.x-fabrics-modal {
      flex-flow: row wrap;
      justify-content: space-around;
    }
  }

  @include respond-to(desktop) {
    &.x-fabrics-modal {
      max-width: 590px;
      margin: 0 $triple-space $triple-space $quadruple-space;

      .substrate-image,
      .substrate-image-wrapper {
        max-width: 270px;
        max-height: 270px;
      }
    }
  }

  @include respond-to(tablet) {
    max-width: 290px;

    &.x-fabrics-modal {
      max-width: none;
      margin: 0 25px $triple-space $double-space;

      .substrate-image,
      .substrate-image-wrapper {
        max-width: 250px;
        max-height: 250px;
      }

      .substrate-description {
        max-width: none;
      }
    }
  }

  @include respond-to(mobile) {
    padding: $double-space;

    &.x-fabrics-modal {
      margin: 0 $double-space $double-space;
      padding: 0;

      .substrate-description {
        max-width: 300px;
        margin: $single-space auto 0;
      }
    }
  }

  @supports (display: grid) {
    max-width: none;
    margin: 0;
  }
}
