
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

.b-afterpay-info-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: $double-space 0;
  text-align: center;

  &.x-text-button {
    display: block;
    justify-content: left;
    margin: 0 0 $triple-space;
    text-align: left;
  }

  .afterpay-messaging-logo {
    vertical-align: text-bottom;
  }

  .afterpay-info-box-currency-note {
    display: inline-block;
    padding-top: $single-space;
    line-height: $content-line-height-xxs;
  }
}
