
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-designer-details {
  @include respond-to(large-desktop) {
    height: 120px;
    border-right: $border-width solid $color-light-grey;

    .b-avatar-image {
      margin: $double-space;
    }
  }

  @include respond-to(small-desktop-down) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-right: 0 none;
    border-bottom: $border-width solid $color-light-grey;
    padding-bottom: $double-space;
    text-align: center;

    .b-avatar-image {
      width: 60px;
      height: 60px;
      margin: $double-space $double-space $single-space;
    }
  }

  @include respond-to(tablet-down) {
    .designer-details-wrapper {
      margin-top: $single-space;
      text-align: center;
    }
  }

  display: flex;
  flex: 1 auto;

  &:visited {
    color: $color-teal-pressed;
  }

  .designer-details-heading {
    font-size: $content-font-size-s;
    line-height: $content-line-height-s;
    color: $color-black;
  }

  .designer-details-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
  }

  .designer-name {
    margin-top: $half-space;
    font-size: $content-font-size-s;
    line-height: $content-line-height-xxsh;
  }

  .by {
    display: inline-block;
    margin: 0 $half-space $half-space 0;
    color: $color-black;
  }

  .designer-details-text {
    font-weight: $content-font-weight-semibold;
    color: $color-gray-500;
  }
}
