@import "src/stylesheets/mixins";
@import "src/stylesheets/variables";

/*
 * Button Group
 */

.b-button-group {
  @include respond-to(desktop) {
    .btn:first-child {
      margin-right: $single-space;
    }
  }

  @include respond-to(desktop, tablet) {
    &.x-hero-image {
      margin: $double-space auto;
      text-align: left;
    }
  }

  @include respond-to(tablet) {
    .btn {
      width: auto;
    }
  }

  @include respond-to(tablet-down) {
    .btn:first-child {
      margin-right: 4%;
    }

    .btn:first-child:nth-last-child(2),
    .btn:first-child:nth-last-child(2) ~ .btn {
      // if there are 2 button
      width: 48%;
    }

    .btn {
      font-size: $base-font-size;
      line-height: $base-line-height;
    }
  }

  @include respond-to(mobile) {
    &.x-hero-image {
      margin: $single-space auto;
      text-align: left;
    }
  }

  clear: both;
  width: 100%;
  margin-bottom: $triple-space;
  white-space: nowrap;
  vertical-align: top;

  .b-confirmation & {
    margin: $double-space 0 $single-space;
  }

  .b-form & {
    margin-top: $double-space;
  }

  .btn {
    box-sizing: border-box;
    min-width: 133px;
    padding: $single-space;
    white-space: normal;
    vertical-align: top;

    &.x-loading::before {
      top: 50%;
      left: 140px;
      margin-top: -17px;
    }
  }
}

.b-do-not-sell {
  .b-button-group {
    text-align: center;
  }

  .button-group-wrapper {
    border-top: none;
  }
}
