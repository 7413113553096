
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";
@import "../../Reusable/FormElements/ButtonGroup/_button-group";
@import "../../Reusable/FormElements/AddToCartButton/add_to_cart_button";

/*
 * Fabric Size /fabric
 */

.b-product-form {
  @include respond-to(large-desktop) {
    .polartec-fleece-award {
      text-align: left;
      padding-left: 129px;

      .award-description {
        width: 75%;
      }
    }

    .polartec-fleece-award-fq {
      padding-left: 104px;
    }

    .polartec-fleece-award-swatch {
      text-align: center;
      padding-left: 97px;

      .award-description {
        text-align: left;
        width: 50%;
      }
    }

    .polartec-fleece-award-metric {
      text-align: center;
      padding-left: 97px;

      .award-description {
        text-align: left;
      }
    }

    .fsc-certification-wrapper {
      display: flex;
      gap: $single-half-space;
      margin-left: 150px;
      align-items: center;
    }

    .fsc-certification-description {
      font-size: $content-font-size-xs;
    }
  }

  @include respond-to(desktop) {
    .image-wrapper {
      float: left;
      min-height: 470px;
      margin-right: 4.65%;
    }

    .x-product-page-image-wrapper {
      width: 66%;
    }

    .x-square-image-page-image-wrapper {
      width: 56%;
    }

    .x-product-page-details-wrapper {
      width: 30%;
    }

    .x-square-image-page-details-wrapper {
      width: 34%;
    }

    .details-wrapper {
      display: inline-block;
    }

    .b-button-group {
      margin-bottom: 16px;

      .x-add-new-item {
        width: 100%;
      }

      .x-add-to-cart-out-of-stock {
        background-color: $color-dark-grey;
      }

      .white-button {
        margin-bottom: $half-space;
      }
    }

    .b-modal .modal-content {
      width: $modal-extra-wide-width;
      padding-top: 0;
    }

    .detail-section {
      margin-bottom: $triple-space;
    }

    .fsc-certification-image {
      max-width: 60px;
      max-height: 90px;
    }
  }

  @include respond-to(small-desktop-down) {
    .fsc-certification-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $single-space;
    }
  }

  @include respond-to(small-desktop) {
    .b-swiper .carousel-image,
    .b-swiper .carousel-image .fallback-image {
      height: auto;
    }

    .polartec-fleece-award {
      text-align: left;
      padding-left: 46px;

      .award-description {
        width: 75%;
      }
    }

    .polartec-fleece-award-fq {
      padding-left: 24px;
    }

    .polartec-fleece-award-swatch {
      text-align: center;
      padding-left: 25px;

      .award-description {
        text-align: left;
        width: 50%;
      }
    }

    .polartec-fleece-award-metric {
      text-align: center;
      padding-left: 25px;

      .award-description {
        text-align: left;
      }
    }

    .fsc-certification-image {
      max-width: 54px;
      max-height: 82px;
    }
  }

  @include respond-to(tablet, desktop) {
    display: block;

    .image-wrapper .vendor-cta-link .cta-text {
      line-height: 38px; // height of btn
    }
  }

  @include respond-to(tablet) {
    .b-button-group {
      display: inline-block;
      width: auto;
      margin-right: $single-space;

      .btn {
        white-space: nowrap;
      }
    }

    .details-wrapper {
      padding-left: $single-space;
    }

    .fsc-certification-description {
      font-size: $content-font-size-xsh;
    }
  }

  @include respond-to(tablet-down) {
    .details-wrapper {
      margin: 0 auto;

      .b-button-group {
        margin-right: 0;
      }

      .big-button {
        width: 100%;
      }
    }

    .image-wrapper {
      margin-bottom: $single-space;
    }

    .detail-section {
      margin-bottom: $single-space;
    }

    .polartec-fleece-award {
      text-align: left;
      padding-left: 50px;

      .award-description {
        width: 75%;
      }
    }

    .polartec-fleece-award-fq {
      padding-left: 24px;
    }

    .polartec-fleece-award-swatch {
      text-align: center;
      padding-left: 25px;

      .award-description {
        text-align: left;
        width: 50%;
      }
    }

    .polartec-fleece-award-metric {
      text-align: center;
      padding-left: 25px;

      .award-description {
        text-align: left;
      }
    }

    .fsc-certification-image {
      max-width: 50px;
      max-height: 80px;
    }

    .fsc-certification-description {
      font-size: $content-font-size-xxsh;
    }
  }

  @include respond-to(mobile) {
    .b-button-group.x-fixed {
      position: fixed;
      z-index: $z-level-3;
      top: 0;
      right: 0;
      left: 0;
      width: auto;
      margin: 0;
      border-bottom: $border-width solid $color-light-grey;
      padding: $double-space;
      background-color: $color-white;
      transition: background-color 1s linear;
    }

    .image-wrapper {
      margin-bottom: $half-space;
    }

    .polartec-fleece-award {
      padding-left: 27px;
    }

    .polartec-fleece-award-swatch {
      text-align: left;

      .award-description {
        width: 65%;
      }
    }

    .polartec-fleece-award-metric {
      text-align: left;
    }
  }

  display: block;
  width: 100%;
  margin: $double-space 0 0;
  white-space: nowrap;

  .edit-design-link {
    display: block;
    margin: $half-space 0 0;
    font-weight: $content-font-weight-bold;
  }

  .h3 {
    display: inline-block;
  }

  .image-wrapper {
    box-sizing: border-box;
    user-select: none;
    position: relative;
    text-align: center;
  }

  .details-wrapper {
    margin-bottom: $double-space;
    white-space: normal;
    vertical-align: top;
  }

  .detail-section {
    height: 100%;
    text-align: left;

    &.x-dashed {
      border-bottom: $border-width dashed $color-light-grey;
    }

    &.x-price-container {
      position: relative;

      .h2 {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
      }
    }

    &.x-quantity-container {
      position: relative;
      min-height: 59px;

      .h2 {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
      }
    }

    &.b-recommended-fabric {
      text-align: center;
    }

    > .h2 {
      margin-bottom: $half-space;
      font-size: $content-font-size-xs;
      font-weight: $content-font-weight-bold;
      line-height: 1;
      text-transform: uppercase;
    }

    .legend {
      font-size: $content-font-size-xs;
      font-weight: $content-font-weight-bold;
      line-height: 1;
      text-transform: uppercase;
    }

    .grand-fabric-note {
      color: $color-dark-grey;
      line-height: $content-line-height-xxxs;
      text-transform: none;
    }

    .h5 {
      width: 60%;
    }

    .tags-list {
      width: 300px;
    }

    .tags-list-element {
      display: inline-block;

      &::after {
        position: relative;
        width: 4px;
        padding-right: $half-space;
        content: ",";
      }

      &:last-child::after {
        display: none;
        content: none;
      }
    }
  }

  .collection-btn {
    width: auto;
    line-height: $content-line-height-l;

    .ico {
      font-size: $content-font-size-l;
      color: $color-gray-500;

      &:hover,
      &:focus {
        color: darken($color-gray-500, $hover);
      }
    }

    &.collected .ico {
      color: $color-dark-blue;
    }
  }

  .b-modal .modal-with-button {
    margin-bottom: 0;
  }

  .radio-group {
    max-width: 400px;

    .type-label {
      font-size: $base-font-size;
      line-height: $content-line-height-xs;
    }
  }

  .btn-choose-fabric-wrapper {
    margin-top: $quadruple-space;
  }

  .b-text-box .main-text {
    max-width: 370px;
  }

  .b-button-group {
    overflow: hidden;
    display: block;
    white-space: normal;

    .btn {
      white-space: nowrap;
    }

    .ico-checkmark {
      margin-right: $single-space;
      transform: scale(0, 0);
      animation: zoom-in 1s ease-in 0s 1 forwards;
    }
  }

  .btn-swatch {
    display: block;
    margin: 0 auto $single-space auto;
    color: $color-blue;
  }

  .fsc-certification-description {
    margin-top: -$double-space;
  }

  .fsc-certification-link {
    cursor: pointer;
    padding-top: 0;
  }

  .ico-test-swatch {
    margin-right: $half-space;
    font-size: $content-font-size-xs;
    line-height: $content-line-height-xs;
  }

  .x-no-image {
    .image,
    .fallback-image {
      max-height: 440px;
    }
  }

  .turnaround-statement {
    padding: 0 0 $single-space;
    text-align: center;

    .ico-calendar-2 {
      margin-right: 3px;
    }
  }

  .turnaround-days {
    font-weight: $content-font-weight-bold;
    color: $color-green-500;
  }

  .polartec-fleece-award {
    display: block;
    margin-bottom: 20px;
    white-space: normal;

    .b-lazy-image {
      margin-right: 15px;
      display: inline-block;
    }

    .award-description {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .b-order-swatch-button {
    border: $border-width solid $color-gray-200;
    border-radius: $border-radius-curved;
    color: $color-gray-700;
    width: 100%;
    margin-top: $double-half-space;

    &:hover,
    &:focus {
      border-color: $color-dark-grey;

      &[disabled] {
        border-color: $color-gray-200;
      }
    }

    &[disabled] {
      opacity: .5;
      cursor: not-allowed;
    }

    @extend %btn;
    @extend %big-button;

    @include respond-to(tablet) {
      margin-top: $double-space;
    }
  }

  .b-swiper {
    padding-bottom: $half-space;

    @include respond-to(large-desktop) {
      padding-top: $single-space;
    }
  }

  .design-detail-image-note {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: $color-black;
    padding-top: $single-half-space;
    user-select: text;

    @include respond-to(large-desktop) {
      padding-top: 0;
    }
  }
}
