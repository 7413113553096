
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

/*
 * Tags List
 */

.b-tags-list {
  @include ellipsis;

  .description {
    display: inline-block;
    margin-bottom: 0;

    &::after {
      padding-right: $half-space;
      content: ",";
    }

    &:last-child::after {
      content: none;
    }
  }

  .h4 {
    display: inline-block;
    padding-right: $single-space;
  }

  &.x-blocked {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $single-space;
    line-height: 32px;

    .tag-link {
      margin-right: 6px;
      border-radius: $border-radius-curved;
      padding: $half-space $single-space;
      text-transform: lowercase;
      color: $color-black;
      background-color: $color-lighter-grey;
    }

    .description::after {
      content: none;
    }
  }
}
