// main width
$chrome-width-1728: 1728px;
$chrome-width-1294: 1294px;
$chrome-width-1072: 1072px;
$chrome-width-675: 675px;

// Borders
$border-radius-curved: 4px;
$border-radius-curved-large: 20px;
$border-radius-none: 0;
$border-width: 1px;
$medium-border-width: 3px;
$thick-border-width: 5px;

// HeaderFooter Only -- do not use in app
$search-input-width: 618px;
$large-text-input-max-width: 487px;

// Grid measurements
$grid-x-25: 24%;
$grid-x-33: 32.3%;
$grid-x-50: 49%;
$grid-x-100: 98%;

// css grid gaps
$grid-gap-xs: 6.57%; // 30px ((456 - (2 * 213)) / 1) * 100 / 456
$grid-gap-s: 6.04%; // 44px ((728 - (2 * 342)) / 1) * 100 / 728
$grid-gap-m: 4.71%; // 44,5px ((944 - (3 * 285)) / 2) * 100 / 944
$grid-gap-l: 3.45%; // 44,6px ((1294 - (4 * 290)) / 3) * 100 / 1294

// Modal widths
$modal-regular-width: 540px;
$modal-wide-width: 660px;
$modal-extra-wide-width: 753px;
$modal-extra-extra-wide-width: 840px;
$modal-tablet-extra-extra-wide-width: 480px;

// product tile image zoom
$image-zoom-hover: scale(1.03) translateZ(1px);

// Stacked images
$small-stacked-image-size-large-desktop: 80px;
$small-stacked-image-size-small-desktop: 60px;
$large-stacked-image-size-large-desktop: 150px;
$small-thumbnail-size-large-desktop: 64px;
$small-thumbnail-size-small-desktop: 50px;
$large-thumbnail-size: 115px;

// Global Subnav
$subnav-standard-width: 297px;

// Width for the Subscribe Form
$subscribe-form-max-width: 780px;
$subscribe-form-image-width: 240px;

// Out of stock brightness
$out-of-stock-brightness: 70%;
$out-of-stock-message-width-curb: 200px;
