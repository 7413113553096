
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/mixins";
@import "src/stylesheets/variables";
@import "src/stylesheets/extends";

.b-design-heading {
  .heading-container,
  .sub-heading-container {
    @extend %flex-split;
  }

  .heading-title {
    @extend %flex-grow;
  }

  .change-design,
  .design-title {
    width: 100%;
    margin-bottom: 0;
  }

  .change-design {
    width: 100%;
    font-size: $content-font-size-m;
    line-height: $content-line-height-m;
  }

  .heading-user {
    min-width: 100px;
    margin-bottom: $triple-space;
    display: flex;
  }

  .tag {
    display: inline-block;
    margin-bottom: 0;

    &::after {
      position: relative;
      width: 4px;
      padding-right: 5px;
      content: ",";
    }

    &:last-child::after {
      display: none;
      content: none;
    }
  }

  .design-title,
  .design-tags {
    margin: $single-space 0;
    font-size: $content-font-size-m;
    line-height: $content-line-height-m;
  }

  .designer {
    @extend %flex-grow;

    font-size: $content-font-size-m;
    line-height: $content-line-height-m;

    .by {
      display: inline-block;
      margin: 0 3px 0 0;
    }

    .designer-link {
      text-decoration: underline;
      color: $color-black;
    }
  }

  .star-list {
    display: inline-block;
    font-size: $content-font-size-xxsh;
  }

  .star-list-item {
    display: inline-block;
    padding-left: 2px;
  }

  .rating-amount {
    margin-right: $half-space;
    font-size: $content-font-size-xxs;
    line-height: $content-line-height-xxxxs;
  }

  @include respond-to(tablet-down) {
    .change-design {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .designer {
      display: block;
    }
  }

  .ico-collection-add {
    vertical-align: text-bottom;
    font-size: $content-line-height-xs;
    line-height: 26px;
  }
}
