
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

.b-product-info {
  overflow: hidden;
  margin-bottom: 54px;

  .h3 {
    margin-bottom: $double-space;
  }

  .fabric-characteristics {
    overflow: hidden;
    padding: $quadruple-space;

    .fabric-headline {
      margin-bottom: $double-space;
      font-weight: $content-font-weight-bold;
    }

    .fabric-name,
    .design-number,
    .fabric-type {
      position: relative;
      display: inline-block;
    }

    .fabric-name.x-large,
    .fabric-specs .spec-title,
    .fabric-cta .fabric-price {
      font-size: $content-font-size-m;
      font-weight: $content-font-weight-bold;
      line-height: $content-line-height-m;
    }

    .fabric-name.x-large {
      margin-bottom: $triple-space;
    }

    .trade-mark {
      position: relative;
      top: -3px;
      font-size: $content-font-size-xxs;
      line-height: 1;
    }

    .design-number::before,
    .fabric-title::after {
      position: relative;
      top: -3px;
      padding: 0 $single-space;
      font-size: $content-font-size-xxxs;
      content: "•";
    }

    .secondary-information {
      font-style: normal;
      color: $color-gray-500;
    }

    .more-details .text,
    .more-details .ico {
      color: $color-gray-500;
    }

    .circle-list {
      list-style: disc inside;

      &.x-indented li {
        margin-left: 15px;
      }
    }

    .section-title {
      font-weight: bold;
    }

    .list-link {
      text-decoration: underline;
      color: $color-black;
      cursor: pointer;
    }

    .fabric-type {
      display: inline-block;
      margin-top: 13px;
    }

    .x-notice {
      font-weight: $content-font-weight-bold;
      text-transform: uppercase;
    }

    .x-foot-note {
      font-family: $font-secondary;
      font-style: italic;
    }

    .heading {
      margin-right: 3px;
      font-weight: $content-font-weight-bold;
    }

    .fabric-description {
      .description-highlighted {
        padding-left: $half-space;
      }

      .description-title {
        padding: $double-space 0 2px;
        font-weight: $content-font-weight-bold;
      }
    }

    .b-collapsible .subtitle {
      margin-top: $single-space;
    }
  }

  .long-description {
    margin-top: $single-space;
  }
}
