
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.colorway-list-wrapper {
  margin-top: $triple-space;

  .colorway-list-title {
    margin-bottom: $half-space;
    font-size: $content-font-size-xs;
    font-weight: $content-font-weight-bold;
    line-height: 1;
    text-transform: uppercase;
  }

  .colorway-list-header {
    display: flex;
    justify-content: space-between;
  }

  .colorway-list-toggle {
    cursor: pointer;
  }

  .colorway-list {
    display: flex;
    flex-wrap: wrap;
  }

  .colorway-item {
    padding: 2px;
    border-radius: 50%;
    box-sizing: border-box;
    border: $border-width solid $color-lighter-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .colorway-item-selected {
    border: 2px solid $color-black;
  }

  .colorway-item-img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  .ico {
    margin-right: $single-space;
  }

  .b-lazy-image {
    padding-top: 0 !important;
  }

  .b-lazy-image .lazy-content {
    position: unset;
  }

  .colorway-item-wrapper {
    margin-right: calc(12%/7);
    margin-bottom: 3px;
    width: 11%;

    &:nth-child(8n) {
      margin-right: 0;
    }
  }
}
