
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-solids-navbar {
  @include respond-to(desktop) {
    margin-bottom: 53px;

    .solids-navbox {
      display: flex;
      flex: 1 auto;
      border-right: $border-width solid $color-light-grey;
    }

    .solids-nav-img {
      width: 80px;
      height: 80px;
      padding: $double-space;
    }
  }

  @include respond-to(tablet-down) {
    @include clearfix;

    flex-direction: column;
    align-items: center;
    margin-bottom: $hextuple-space;

    .solids-navbox {
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      border-bottom: $border-width solid $color-light-grey;
      padding-top: $double-space;
      padding-bottom: $double-space;
      text-align: center;

      .b-stacked-images {
        margin: 0;
      }
    }

    .solids-nav-img {
      width: 60px;
      height: 60px;
    }

    .solids-nav-header {
      margin-top: 12px;
    }
  }

  display: flex;
  max-width: 100%;
  border-radius: $border-radius-curved;
  background-color: $color-lighter-grey;

  .solids-navbox {
    align-items: center;
  }

  .solids-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
  }

  .solids-nav-header {
    margin-bottom: $half-space;
    font-size: $content-font-size-s;
    line-height: $content-line-height-s;
    color: $color-black;
  }
}
